@media only screen and (max-width: 767px) {
  .pb-50 {
    padding-bottom: 20px;
  }

  .pt-80 {
    padding-top: 50px;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .startp-nav {
    background: #fff;
    padding-top: 5px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }

  .navbar-light .navbar-brand {
    padding-top: 0;
  }

  .navbar-light .navbar-brand img {
    max-width: 100px;
  }

  .btn {
    font-size: 13px;
    padding: 13px 33px;
  }

  .ptb-80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-title {
    margin-bottom: 30px;
  }

  .section-title h2 {
    font-size: 25px;
  }

  .owl-theme .owl-dots {
    margin-top: 5px;
  }

  #overflow-slide_5 .owl-theme .owl-dots {
    margin-top: -5px !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    width: 12px;
    height: 12px;
    margin: 0 3px;
  }

  .startp-nav nav .others-option {
    margin-left: 0;
    display: none;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
    border-radius: 0;
    outline: 0;
  }

  .navbar-light .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .startp-nav nav ul {
    background-color: #fff;
    margin-top: 15px;
  }

  .startp-nav nav .navbar-nav .nav-item {
    padding: 6px 0;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    margin-left: 0;
    margin-right: 0;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li {
    border-left: 1px dashed #eee;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu {
    box-shadow: unset;
    position: relative;
    top: 0;
    left: 0;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
    opacity: 1;
    visibility: visible;
    margin-top: 10px;
    width: 100%;
    border-radius: 0;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li a {
    padding: 5px 15px;
    font-size: 13px;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li:last-child a {
    padding-bottom: 0;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li:first-child a {
    padding-top: 0;
  }

  .startp-nav nav .navbar-nav {
    overflow-y: scroll;
    height: 350px;
    display: block;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu {
    position: relative;
    left: 0;
    top: 0;
    opacity: 1 !important;
    visibility: visible !important;
    top: 0 !important;
    padding-left: 20px;
    margin-bottom: 8px;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu .nav-item:first-child a {
    padding-top: 0;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li:hover .dropdown_menu {
    top: 0 !important;
  }

  .main-banner {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 50px;
    background-position: center;
  }

  .main-banner .hero-content {
    margin-bottom: 30px;
  }

  .main-banner .hero-content h1 {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .main-banner .banner-image {
    text-align: center;
  }

  .main-banner .banner-image img {
    display: none;
    position: relative;
  }

  .main-banner .banner-image img:last-child {
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .main-banner .banner-form {
    padding: 30px 20px;
    margin: 0 !important;
  }

  .hosting-main-banner {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 75px;
  }

  .hosting-main-banner .hosting-banner-content h1 {
    font-size: 25px;
  }

  .hosting-main-banner .hosting-banner-content ul li {
    font-size: 13px;
  }

  .hosting-main-banner .hosting-banner-content p {
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: 20px;
  }

  .hosting-main-banner .hosting-banner-image {
    text-align: center;
    margin-top: 50px;
  }

  .hosting-main-banner .hosting-banner-image img {
    display: none;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(10) {
    display: block;
    position: relative;
  }

  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5 {
    display: none;
  }

  .boxes-area {
    margin-top: 0;
    padding-bottom: 20px;
  }

  .single-box {
    margin-bottom: 30px;
    text-align: center;
  }

  .single-box h3 {
    font-size: 17px;
  }

  .domain-search-content h2 {
    font-size: 20px;
  }

  .domain-search-content form {
    border-radius: 0;
    padding: 20px;
  }

  .domain-search-content form .form-control {
    width: 100%;
    border: 1px solid #eee;
  }

  .domain-search-content form .domain-select {
    width: 100%;
    padding-right: 0;
    margin: 15px 0;
  }

  .domain-search-content form .domain-select .form-control {
    padding: 10px 15px;
  }

  .domain-search-content form button {
    float: unset;
    width: unset;
    height: unset;
    padding: 13px 40px;
  }

  .domain-search-content ul {
    display: block;
    margin-top: 15px;
  }

  .domain-search-content ul li {
    display: inline-block;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    padding: 0 15px;
    margin-top: 15px;
  }

  .why-choose-us-image {
    margin-bottom: 15px;
  }

  .why-choose-us-image img {
    display: none;
  }

  .why-choose-us-image img:last-child {
    display: block;
  }

  .single-why-choose-us {
    margin-top: 30px !important;
  }

  .single-why-choose-us .icon i::before {
    font-size: 35px;
  }

  .single-why-choose-us h3 {
    font-size: 17px;
  }

  .services-content .box {
    margin-top: 20px;
  }

  .services-right-image {
    text-align: center;
    margin-top: 45px;
  }

  .services-right-image img {
    display: none;
    position: relative;
  }

  .services-right-image img:last-child {
    display: block;
    margin: auto;
  }

  .services-right-image.single-right-image img.bg-image {
    display: none;
  }

  .services-left-image {
    text-align: center;
    margin-bottom: 45px;
  }

  .services-left-image img {
    display: none;
    position: relative;
  }

  .services-left-image img:last-child {
    display: block;
    margin: auto;
  }

  .services-left-image.single-left-image img.bg-image {
    display: none;
  }

  .services-area-two {
    padding-bottom: 20px;
  }

  .single-services-box h3 {
    font-size: 17px;
  }

  .features-area {
    padding-bottom: 20px;
  }

  .single-features {
    padding-left: 20px;
    text-align: center;
  }

  .single-features .icon {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
  }

  .single-features h3 {
    font-size: 17px;
  }

  .hosting-features-area {
    padding-bottom: 20px;
  }

  .single-hosting-features h3 {
    font-size: 17px;
  }

  .cta-content {
    text-align: center;
    margin-bottom: 25px;
  }

  .cta-content h3 {
    font-size: 20px;
  }

  .cta-right-content {
    text-align: center;
  }

  .single-team {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .single-team .team-image {
    padding-left: 20px;
    padding-right: 20px;
  }

  .single-team .team-content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .single-team .team-content .team-info {
    margin-right: 10px;
    margin-left: 10px;
  }

  .single-team .team-content .team-info h3 {
    font-size: 17px;
  }

  .funfact {
    margin-bottom: 30px;
  }

  .funfact h3 {
    font-size: 25px;
    margin-bottom: 3px;
  }

  .contact-cta-box {
    margin: 20px auto 0;
    padding: 30px 20px;
    text-align: center;
  }

  .contact-cta-box h3 {
    font-size: 20px;
  }

  .contact-cta-box .btn {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 15px;
  }

  .works-area {
    padding-bottom: 20px;
  }

  .single-works .icon {
    right: 5px;
    width: 38px;
    height: 38px;
    line-height: 36px;
  }

  .single-works .icon svg {
    width: 15px;
  }

  .single-works .works-content {
    padding: 15px;
  }

  .single-works .works-content h3 {
    font-size: 17px;
  }

  .single-works:hover .icon {
    top: 5px;
  }

  .pricing-area {
    padding-bottom: 20px;
  }

  .pricing-table {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .pricing-table .pricing-header h3 {
    font-size: 17px;
  }

  .pricing-table .price {
    margin-bottom: 25px;
  }

  .pricing-table .price span {
    font-size: 27px;
  }

  .single-pricing-table {
    padding: 30px;
    margin-bottom: 30px;
  }

  .single-pricing-table .pricing-header h3 {
    font-size: 17px;
  }

  .single-pricing-table .pricing-header i::before {
    font-size: 40px;
  }

  .single-pricing-table .price span {
    font-size: 27px;
  }

  .feedback-slides .client-feedback .single-feedback {
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }

  .feedback-slides .client-feedback .single-feedback .quote-icon {
    margin: 0 auto 15px;
    max-width: 35px;
  }

  .feedback-slides .client-feedback .single-feedback .client-img img {
    width: 60px;
    height: 60px;
  }

  .feedback-slides .client-feedback .single-feedback h3 {
    font-size: 16px;
    margin: 10px 0 0;
  }

  .feedback-slides .client-feedback .single-feedback p {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .feedback-slides .client-feedback .single-feedback::before {
    display: none;
  }

  /* .feedback-slides .client-thumbnails {
    margin: 0;
  } */
  .feedback-slides .client-thumbnails .next-arrow {
    right: -40px;
  }

  .pricing-table .pricing-header {
    padding: 25px 20px;
  }

  .feedback-slides .client-thumbnails .prev-arrow {
    left: -40px;
  }

  .feedback-slides .client-thumbnails .next-arrow,
  .feedback-slides .client-thumbnails .prev-arrow {
    background: rgba(0, 0, 0, 0.55);
    color: #ffffff;
    border: none;
  }

  .testimonials-slides {
    max-width: 100%;
    margin: 0;
  }

  .testimonials-slides::before,
  .testimonials-slides::after {
    display: none;
  }

  .testimonials-slides .client-info .image {
    padding-right: 15px;
  }

  .testimonials-slides .client-info .title h3 {
    font-size: 17px;
  }

  .testimonials-slides p {
    font-size: 14px;
  }

  .single-feedback-item {
    padding: 40px 20px 90px;
  }

  .single-feedback-item::before {
    font-size: 30px;
    left: 15px;
  }

  .ready-to-talk {
    padding-top: 50px;
    padding-bottom: 145px;
  }

  .ready-to-talk h3 {
    font-size: 20px;
  }

  .ready-to-talk span {
    display: block;
    margin-top: 25px;
  }

  .partner-section h5 {
    font-size: 17px;
    line-height: 23px;
  }

  .partner-section .partner-inner {
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-area {
    padding-bottom: 20px;
  }

  .single-blog-post .blog-post-content h3 {
    font-size: 17px;
  }

  .single-blog-post .blog-image a img {
    width: 100%;
  }

  .page-title-area {
    padding-top: 130px;
    padding-bottom: 65px;
  }

  .page-title-area h2 {
    font-size: 22px;
  }

  .page-title-area .shape8 {
    top: auto;
    right: auto;
    left: 15px;
    bottom: 20px;
  }

  .pb-80 {
    padding-bottom: 50px;
  }

  .page-title-area .shape7 {
    left: 15%;
    top: 29%;
  }

  .about-image {
    margin-bottom: 45px;
  }

  .about-inner-area {
    margin-top: 0px;
  }

  .about-inner-area .about-text {
    margin-top: 30px;
  }

  .about-inner-area .about-text h3 {
    font-size: 17px;
  }

  .project-details-desc h3 {
    font-size: 17px;
    margin-top: 0;
  }

  .project-details-desc .project-details-information .single-info-box {
    float: left;
    width: 50%;
    margin-top: 20px;
  }

  .project-details-desc .project-details-information .single-info-box h4 {
    font-size: 17px;
  }

  .faq-accordion {
    padding: 30px 20px;
  }

  .faq-accordion .accordion .accordion-item .accordion-title {
    font-size: 15px;
  }

  .error-area {
    height: 100%;
    padding-top: 140px;
    padding-bottom: 100px;
  }

  .faq-contact {
    padding: 30px 20px;
  }

  .faq-contact h3 {
    font-size: 20px;
  }

  .pagination-area {
    margin-top: 25px;
  }

  .blog-details-area .blog-details .article-img .date {
    width: 80px;
    height: 80px;
    font-size: 20px;
    padding-top: 16px;
    line-height: 23px;
  }

  /* .blog-details-area .blog-details .article-content {
    padding: 30px 20px;
  } */

  .blog-details-area .blog-details .article-content ul li {
    margin-bottom: 5px;
  }

  .blog-details-area .blog-details .article-content ul.category {
    margin-bottom: 20px;
  }

  .blog-details-area .blog-details .article-content h3 {
    font-size: 17px;
    line-height: 24px;
  }

  .blog-details-area .blog-details .article-content p {
    margin-top: 5px;
  }

  .blog-details-area .blog-details .article-content .blockquote {
    padding: 18px;
  }

  .blog-details-area .blog-details .article-content .blockquote p {
    font-size: 14px;
  }

  .blog-details-area .blog-details .article-content .share-post ul li a {
    line-height: 35px;
  }

  .blog-details-area .post-controls-buttons {
    padding: 30px 20px;
  }

  .blog-details-area .post-comments {
    padding: 10px;
  }

  .blog-details-area .post-comments h3 {
    font-size: 17px;
  }

  .blog-details-area .post-comments .single-comment {
    padding-left: 0;
  }

  .blog-details-area .post-comments .single-comment .comment-img {
    position: relative;
    margin-bottom: 25px;
  }

  .blog-details-area .post-comments .single-comment.left-m {
    margin-left: 20px;
  }

  .blog-details-area .leave-a-reply {
    padding: 30px 20px;
  }

  .blog-details-area .leave-a-reply h3 {
    font-size: 17px;
  }

  .contact-info-area {
    padding-bottom: 20px;
  }

  .contact-info-box {
    padding: 30px 20px;
    margin-bottom: 30px;
  }

  .contact-info-box h3 {
    font-size: 17px;
  }

  #contactForm {
    margin-top: 45px;
  }

  .coming-soon-area {
    height: 100%;
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .coming-soon-area .social-list {
    bottom: 20px;
  }

  .coming-soon-content h1 {
    font-size: 30px;
  }

  .coming-soon-content p {
    font-size: 14px;
  }

  .coming-soon-content form .submit-btn {
    position: relative;
    right: 0;
    top: 0;
    height: 45px;
    border-radius: 40px;
    width: 130px;
    font-size: 14px;
    margin-top: 18px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0 0 0 4px rgba(255, 255, 255, 0.3);
  }

  .coming-soon-content #timer div {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    padding-top: 18px;
    font-size: 30px;
    margin-bottom: 25px;
  }

  .coming-soon-content #timer div span {
    font-size: 13px;
  }

  .error-content {
    margin-top: 0;
  }

  .error-content .notfound-404 {
    height: 95px;
    margin-bottom: 25px;
  }

  .error-content .notfound-404 h1 {
    font-size: 75px;
  }

  .error-content h3 {
    font-size: 19px;
    margin-bottom: 15px;
  }

  .error-content p {
    font-size: 13px;
  }

  .woocommerce-topbar {
    text-align: center;
  }

  .woocommerce-topbar .woocommerce-result-count {
    margin-bottom: 10px;
  }

  .woocommerce-topbar .woocommerce-topbar-ordering .form-control {
    height: 40px;
    font-size: 12px;
    text-align: center;
  }

  .single-products .products-content h3 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .single-products .products-content .add-to-cart-btn {
    font-size: 13px;
  }

  .modal-content button.close {
    z-index: 2;
  }

  .modal-content .products-content {
    text-align: center;
    padding: 25px 10px;
  }

  .modal-content .products-content h3 {
    font-size: 17px;
  }

  .products-details {
    margin-top: 20px;
  }

  .products-details h3 {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .products-details form button {
    padding: 0 13px;
  }

  .products-details form .quantity input {
    height: 45px;
  }

  .products-details form .add-to-wishlist-btn {
    width: 40px;
  }

  .products-details-tabs #tabs li {
    display: block;
    margin-bottom: 2px;
  }

  .cart-table table thead tr th {
    padding: 15px 15px;
    white-space: nowrap;
  }

  .cart-table table tbody tr td {
    padding-left: 10px;
    padding-right: 10px;
    white-space: nowrap;
  }

  .cart-buttons {
    text-align: center;
  }

  .cart-buttons .continue-shopping-box {
    margin-bottom: 12px;
  }

  .cart-buttons .text-right {
    text-align: center !important;
  }

  .cart-totals {
    padding: 20px;
    margin: 35px auto 0;
  }

  .cart-totals h3 {
    font-size: 17px;
  }

  .checkout-area .title {
    font-size: 17px;
  }

  .billing-details {
    padding: 20px;
  }

  .order-details {
    padding: 20px;
    margin-top: 30px;
  }

  .services-details-desc h3 {
    font-size: 18px;
  }

  .services-details-desc .services-details-accordion .accordion .accordion-title {
    font-size: 14px;
  }

  .services-details-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 30px;
  }

  .services-details {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .services-details-area .separate {
    margin-top: 30px;
  }

  .features-details-desc h3 {
    font-size: 18px;
  }

  .features-details-desc .features-details-accordion .accordion .accordion-title {
    font-size: 14px;
  }

  .features-details-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 30px;
  }

  .features-details {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .features-details-area .separate {
    margin-top: 30px;
  }

  .repair-main-banner {
    padding-top: 110px;
    padding-bottom: 60px;
  }

  .repair-banner-content h1 {
    line-height: 1.4;
    margin-bottom: 17px;
    font-size: 25px;
  }

  .repair-banner-image {
    margin-top: 60px;
  }

  .repair-banner-image img:nth-child(2) {
    top: 0;
    right: 0;
  }

  .repair-banner-image img:nth-child(3) {
    top: -35px;
  }

  .repair-banner-image img:nth-child(4) {
    display: none;
  }

  .repair-about-content {
    margin-bottom: 40px;
  }

  .repair-about-content .sub-title {
    font-size: 14px;
  }

  .repair-about-content h2 {
    font-size: 20px;
  }

  .repair-about-content ul li {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  .repair-about-image {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .repair-about-image img:nth-child(2) {
    width: 250px;
    position: relative;
  }

  .repair-about-image img:nth-child(4),
  .repair-about-image img:nth-child(5) {
    display: none;
  }

  .repair-services-area {
    padding-bottom: 20px;
  }

  .single-repair-services {
    padding: 30px 20px;
  }

  .single-repair-services h3 {
    font-size: 17px;
  }

  .single-repair-services .icon {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }

  .single-repair-services .icon i::before {
    font-size: 25px;
  }

  .repair-cta-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cta-repair-content {
    text-align: center;
    margin-bottom: 30px;
  }

  .cta-repair-content h3 {
    font-size: 21px;
    margin-bottom: 17px;
  }

  .cta-repair-content p {
    font-size: 13px;
  }

  .cta-repair-img {
    text-align: center;
  }

  .single-repair-feedback {
    border-radius: 5px;
    margin-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .single-repair-feedback::after {
    font-size: 30px;
    left: 10px;
  }

  .single-repair-feedback .client-img {
    margin-bottom: 29px;
    padding-left: 85px;
    padding-top: 13px;
  }

  .single-repair-feedback .client-img img {
    width: 70px;
    height: 70px;
  }

  .single-repair-feedback .client-img span {
    margin-top: 5px;
  }

  .single-repair-feedback .client-img h3 {
    font-size: 17px;
  }

  .single-repair-feedback p {
    font-size: 14px;
  }

  .single-repair-box {
    padding: 35px 20px;
    margin-bottom: 30px;
  }

  .single-repair-box .icon {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }

  .single-repair-box .icon i::before {
    font-size: 25px;
  }

  .single-repair-box .icon h3 {
    font-size: 17px;
  }

  .single-repair-box .back-icon i::before {
    font-size: 170px;
  }

  .iot-main-banner {
    padding-top: 115px;
    padding-bottom: 60px;
  }

  .iot-why-choose-us::before {
    display: none;
  }

  .iot-why-choose-us {
    padding-bottom: 20px;
  }

  .iot-banner-content span {
    font-size: 14px;
  }

  .iot-banner-content h2 {
    font-size: 25px;
    margin-bottom: 17px;
    line-height: 1.4;
  }

  .single-feedback-item .client-info .title span {
    font-size: 13px;
  }

  .iot-banner-image {
    position: relative;
    right: 0;
    top: 0;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 50px;
  }

  .iot-banner-image img:first-child {
    max-width: 100%;
  }

  .iot-banner-image img:last-child {
    display: none;
  }

  .animate-border span {
    top: 78%;
    left: 41%;
  }

  .iot-services-area {
    padding-bottom: 20px;
  }

  .single-iot-services {
    padding: 30px 20px;
  }

  .single-iot-services .icon {
    width: 65px;
    height: 65px;
    line-height: 65px;
  }

  .single-iot-services .icon i::before {
    font-size: 25px;
  }

  .single-iot-services h3 {
    font-size: 17px;
  }

  .iot-cta-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .cta-iot-content {
    text-align: center;
    margin-top: 35px;
  }

  .cta-iot-content h3 {
    font-size: 20px;
    line-height: 1.5;
  }

  .iot-features-content {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 30px;
  }

  .iot-features-content h3 {
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1.5;
  }

  .iot-features-image {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .single-iot-box {
    padding: 25px 20px;
    margin-bottom: 30px;
  }

  .single-iot-box .icon {
    width: 85px;
    height: 85px;
    line-height: 85px;
  }

  .single-iot-box .icon img {
    width: 40px;
  }

  .single-iot-box .icon i::before {
    font-size: 25px;
  }

  .single-iot-box h3 {
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .footer-area {
    padding-top: 50px;
  }

  .single-footer-widget {
    padding: 0 !important;
    margin-bottom: 30px;
  }

  .single-footer-widget h3 {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .copyright-area {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .repair-why-choose-us::before {
    display: none;
  }

  .repair-why-choose-us {
    padding-bottom: 20px;
  }

  .feedback-slides .client-thumbnails .item .img-fill img {
    width: 50px;
  }
}


@media only screen and (max-width: 400px) {
  .section-title h2 {
    font-size: 22px;
  }
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 25px;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    margin-left: 10px;
    margin-right: 10px;
  }

  .startp-nav nav .others-option {
    margin-left: 0;
    display: none;
  }

  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu {
    width: 200px;
    right: 0;
    left: auto;
  }

  .startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu {
    left: -106%;
  }

  .navbar-light .navbar-brand img {
    max-width: 100px;
  }

  .main-banner {
    height: 100%;
    padding-top: 70px;
    padding-bottom: 0px;
  }

  .main-banner .banner-image {
    text-align: center;
    margin-top: 45px;
  }

  .main-banner .banner-image img {
    position: relative;
    display: none;
  }

  .main-banner .banner-image img:last-child {
    display: block;
    margin: auto;
    max-width: 500px;
  }

  .main-banner .hero-content h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .funfact {
    margin-bottom: 30px;
  }

  .main-banner .banner-form {
    padding: 50px;
    margin: 45px 0 !important;
  }

  .hosting-main-banner {
    height: 100%;
    padding-top: 90px;
    padding-bottom: 130px;
  }

  .hosting-main-banner .hosting-banner-content h1 {
    font-size: 30px;
  }

  .hosting-main-banner .hosting-banner-content ul li {
    font-size: 13px;
  }

  .hosting-main-banner .hosting-banner-content p {
    font-size: 16px;
  }

  .hosting-main-banner .hosting-banner-image {
    margin-top: 50px;
  }

  .hosting-main-banner .hosting-banner-image img {
    position: relative;
    display: none;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(10) {
    display: block;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .shape1,
  .shape2,
  .shape3,
  .shape4,
  .shape5,
  .shape7 {
    display: none;
  }

  .boxes-area {
    padding-bottom: 50px;
    margin-top: -65px;
  }

  .single-box {
    margin-bottom: 30px;
  }

  .why-choose-us-image {
    margin-bottom: 65px;
  }

  .why-choose-us-image img {
    display: none;
  }

  .why-choose-us-image img:nth-child(3) {
    display: block;
  }

  .why-choose-us-image img:last-child {
    display: block;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .services-right-image {
    text-align: center;
    margin-top: 45px;
  }

  .services-right-image img {
    display: none;
    position: relative;
  }

  .services-right-image img:last-child {
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .services-right-image.single-right-image img.bg-image {
    display: none;
  }

  .services-left-image {
    text-align: center;
    margin-bottom: 45px;
  }

  .services-left-image img {
    display: none;
    position: relative;
  }

  .services-left-image img:last-child {
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .services-left-image.single-left-image img.bg-image {
    display: none;
  }

  .single-features {
    padding-left: 20px;
  }

  .single-features .icon {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 25px;
  }

  .about-image {
    margin-bottom: 45px;
  }

  .about-inner-area {
    margin-top: 30px;
  }

  .about-inner-area .about-text {
    margin-top: 30px;
  }

  .single-feedback-item {
    padding: 40px 80px 90px;
  }

  .cta-content h3 {
    line-height: 1.5;
  }

  .pricing-area {
    padding-bottom: 50px;
  }

  .pricing-table {
    margin-bottom: 30px;
  }

  .single-pricing-table {
    margin-bottom: 30px;
  }

  .page-title-area {
    padding-top: 130px;
    padding-bottom: 50px;
  }

  .page-title-area .shape8 {
    top: auto;
    right: auto;
    left: 20px;
    bottom: 20px;
  }

  .project-details-desc .project-details-information .single-info-box {
    width: 33.3333%;
  }

  .contact-info-area {
    padding-bottom: 50px;
  }

  .contact-info-box {
    margin-bottom: 30px;
  }

  #contactForm {
    margin-top: 45px;
  }

  .coming-soon-content h1 {
    font-size: 35px;
  }

  .coming-soon-content p {
    font-size: 15px;
  }

  .coming-soon-content #timer div {
    padding-top: 20px;
    font-size: 30px;
  }

  .coming-soon-content #timer div span {
    font-size: 14px;
  }

  .error-content .notfound-404 {
    height: 205px;
  }

  .error-content .notfound-404 h1 {
    font-size: 170px;
  }

  .products-details {
    margin-top: 25px;
  }

  .services-details {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .services-details-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 40px;
  }

  .services-details-area .separate {
    margin-top: 40px;
  }

  .features-details {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .features-details-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 40px;
  }

  .features-details-area .separate {
    margin-top: 40px;
  }

  .mean-container .mean-bar {
    padding: 10px 0 0;
  }

  .mean-container.startp-mobile-nav .logo {
    top: 16px !important;
  }

  .repair-main-banner {
    padding-top: 155px;
  }

  .repair-banner-content {
    text-align: center;
    margin-bottom: 115px;
  }

  .repair-banner-content h1 {
    margin-bottom: 18px;
    font-size: 30px;
  }

  .repair-banner-image img:nth-child(4) {
    left: 0;
    top: -40px;
    margin: 0 auto;
  }

  .repair-about-content {
    margin-bottom: 45px;
  }

  .repair-about-image {
    text-align: center;
    padding: 35px 0;
  }

  .repair-about-image img:nth-child(2) {
    position: relative;
  }

  .repair-about-image img:nth-child(4),
  .repair-about-image img:nth-child(5) {
    display: none;
  }

  .repair-cta-area {
    padding-top: 80px;
  }

  .cta-repair-content {
    margin-bottom: 30px;
  }

  .repair-why-choose-us::before {
    height: 40%;
  }

  .iot-main-banner {
    padding-top: 150px;
    padding-bottom: 100px;
  }

  .iot-banner-content span {
    font-size: 14px;
  }

  .iot-banner-content h2 {
    font-size: 30px;
  }

  .iot-banner-image {
    position: relative;
    right: 0;
    top: 0;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 80px;
  }

  .iot-banner-image img:first-child {
    max-width: 100%;
  }

  .animate-border span {
    top: 70%;
    left: 42%;
  }

  .iot-cta-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .cta-iot-content {
    text-align: center;
    margin-top: 40px;
  }

  .iot-features-content {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .iot-features-image {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 40px;
  }

  .iot-why-choose-us {
    padding-bottom: 50px;
  }

  .iot-why-choose-us::before {
    display: none;
  }

  .single-iot-box {
    margin-bottom: 30px;
  }

  .single-footer-widget {
    margin-bottom: 30px;
  }

  .copyright-area {
    margin-top: 50px;
  }

  .contact-cta-box {
    margin: 20px auto 0;
  }

  .feedback-slides .client-thumbnails .item .img-fill img {
    width: 60px;
  }

  .single-footer-widget.pl-5 {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 25px;
  }

  .single-box p {
    font-size: 13px;
  }

  .iot-cta-area {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .single-why-choose-us h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .main-banner .banner-image {
    margin-left: 20px;
  }

  .single-box h3 {
    font-size: 14px;
    font-weight: 600;
  }

  .feedback-slides .client-feedback .single-feedback h3 {
    font-size: 16px;
  }

  .main-banner .hero-content h1 {
    font-size: 35px;
  }

  .main-banner .banner-image img:nth-child(1) {
    top: -140px;
    left: -120px;
  }

  .main-banner .banner-image img:nth-child(2) {
    right: 160px;
    top: -286px;
  }

  .main-banner .banner-image img:nth-child(3) {
    left: -30px;
    top: -15px;
  }

  .main-banner .banner-image img:nth-child(4) {
    right: 15%;
    top: 105px;
  }

  .main-banner .banner-image img:nth-child(5) {
    left: 15%;
    top: -120px;
  }

  .repair-about-content ul li span {
    font-size: 13px;
  }

  .main-banner .banner-image img:nth-child(6) {
    right: 37%;
    top: -160px;
  }

  .main-banner .banner-image img:nth-child(7) {
    right: 20px;
    top: -110px;
  }

  .main-banner .banner-image img:nth-child(8) {
    right: 25px;
    top: -140px;
  }

  .main-banner .banner-image img:nth-child(9) {
    right: 28%;
    top: -25px;
  }

  .main-banner .banner-image img:nth-child(10) {
    left: 29%;
    top: -35px;
  }

  .main-banner .banner-image img:nth-child(11) {
    left: 26%;
    top: -160px;
  }

  .main-banner .banner-image img:nth-child(12) {
    left: 15px;
    top: -110px;
  }

  .main-banner .banner-image img:nth-child(13) {
    left: 50%;
    top: -18px;
  }

  .main-banner .banner-image img:nth-child(14) {
    right: 98px;
    top: -20px;
  }

  .hosting-main-banner .hosting-banner-content h1 {
    font-size: 35px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(1) {
    top: -85px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(2) {
    top: 175px;
    left: 30px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(3) {
    top: 170px;
    right: 30px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(4) {
    left: 18%;
    top: -23px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(5) {
    right: 0;
    top: -115px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(6) {
    left: 2%;
    top: -173px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(7) {
    top: -244px;
    left: -73px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(8) {
    left: 11%;
    top: -200px;
  }

  .hosting-main-banner .hosting-banner-image img:nth-child(9) {
    top: 40px;
    left: 0;
  }

  .shape1 {
    display: none;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    margin-left: 10px;
    margin-right: 10px;
  }

  .services-left-image.single-left-image img:first-child {
    top: -30px;
  }

  .services-right-image.single-right-image img:first-child {
    top: -30px;
  }

  .startp-nav.p-relative nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu {
    left: auto;
    right: 245px;
  }

  .repair-main-banner {
    padding-top: 185px;
    padding-bottom: 110px;
  }

  .repair-banner-image img:nth-child(4) {
    display: none;
  }

  .repair-banner-image img:nth-child(3) {
    top: -35px;
  }

  .repair-banner-image img:nth-child(2) {
    top: -30px;
  }

  .repair-team-area .single-team .team-content {
    padding-left: 0;
    padding-right: 0;
  }

  .iot-main-banner {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  .iot-banner-image img:first-child {
    max-width: 470px;
  }

  .iot-banner-image img:last-child {
    top: -10px;
  }

  .iot-team-area .single-team .team-content {
    padding-left: 0;
    padding-right: 0;
  }

  .single-blog-post .blog-post-content h3 {
    font-size: 14px;
  }

  .single-blog-post .blog-post-content p{
    font-size: 11px;
  }

  .services-content .box {
    padding: 15px 6px 15px 28px;
    font-size: 12px;
  }

  .services-content .box svg {
    left: 10px;
    top: 16px;
    width: 15px;
  }

  .main-banner {
    height: 815px;
  }
}

@media only screen and (min-width: 1550px) {
  .iot-banner-image {
    right: 90px;
    top: 56%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .iot-banner-image img:first-child {
    max-width: 90%;
  }

  .iot-banner-image img:last-child {
    left: -128px;
    top: 50px;
  }

  .animate-border span {
    top: 51%;
    left: 70%;
  }
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .startp-nav nav .navbar-nav .nav-item a {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .navbar-light .navbar-brand img {
    max-width: 125px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .startp-nav nav .navbar-nav .nav-item a {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
}