.primary-color {
  color: #2e70a6;
}

.secondary-color {
  color: #f7993b;
}

p.aboutContent {
  font-size: 18px;
  text-align: center;
}

.slick-prev:before,
.slick-next:before {
  color: #f7993b !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

img {
  max-width: 100%;
}

a {
  color: #0e314c;
  display: inline-block;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  outline: 0 !important;
}

a:hover,
a:focus {
  color: #f7993b;
  text-decoration: none;
}

.breadcrumb li {
  font-size: 16px;
  color: black;
}

.breadcrumb li.active {
  font-weight: 500;
  color: black;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  color: #000;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 500;
}

.ptb-80 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-50 {
  padding-bottom: 50px;
}

.bg-f7fafd {
  background-color: #f7fafd;
}

.bg-f9f6f6 {
  background-color: #f9f6f6;
}

.bg-f9fafb {
  background-color: #f9fafb;
}

.bg-0f054b {
  background-color: #0f054b;
}

.spacing h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;
  width: 70%;
}

.spacing span {
  color: #f7993b;
}

.clientSlider2 .slick-dots {
  bottom: 70px !important;
  right: -280px !important;
}

.clientSlider2 .slick-dots li.slick-active button:before {
  color: #db8f04;
}

.mainheading {
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
  width: 70%;
  margin: auto;
}

.faq-title h6 {
  font-weight: 500;
}

.mainheading span.prohead {
  color: #f7993b !important;
}

.cardSlider span.carousel-control-prev-icon {
  background: #ff8000;
  position: absolute;
  left: -50px;
  border-radius: 50%;
}

.cardSlider span.carousel-control-prev-icon::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 6px;
  transform: rotate(45deg);
  width: 13px;
  border: 2px solid white;
  height: 13px;
  border-right: 0px;
  border-top: 0px;
}

.cardSlider span.carousel-control-next-icon {
  background: #ff8000;
  position: absolute;
  right: -50px;
  border-radius: 50%;
}

.cardSlider span.carousel-control-next-icon::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 6px;
  transform: rotate(45deg);
  width: 13px;
  border: 2px solid white;
  height: 13px;
  border-left: 0px;
  border-bottom: 0px;
}

.owl-theme .owl-nav {
  margin-top: 0;
}

.owl-theme .owl-dots {
  margin-top: 20px;
  line-height: 0.01;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background: #d6d6d6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: #2882c4;
}

.btn-primary {
  color: #ffffff;
  background-color: #f7993b;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #ffffff;
  background: #0e314c;
  -webkit-transform: unset;
  transform: unset;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.btn-primary.focus,
.btn-primary:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary .show>.btn-primary.dropdown-toggle {
  color: #ffffff;
  background: #0e314c;
  -webkit-transform: unset;
  transform: unset;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #2882c4;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  opacity: 1;
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus {
  background: #0e314c;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.btn {
  font-weight: 300;
  font-size: 18px;
  border: none;
  padding: 10px 40px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn::after,
.btn::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #2d6fa6;
  z-index: -1;
}

.btn::after {
  left: 0;
  border-radius: 4px 0 0 4px;
}

.btn::before {
  right: 0;
  border-radius: 0 4px 4px 0;
}

.btn:hover::after,
.btn:hover::before {
  width: 50%;
}

.form-group {
  margin-bottom: 18px;
}

label {
  color: #0e314c;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.form-control {
  height: 45px;
  padding: 10px 15px;
  font-size: 13px;
  color: black;
  border: 1px solid #eeeeee;
  border-radius: 0;
  background: #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-control:focus {
  outline: 0;
  background: #ffffff;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  border-color: #2882c4;
}

.section-title {
  text-align: center;
  margin-bottom: 60px;
}

.section-title h2 {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

.section-title p {
  max-width: 600px;
  margin: 0 auto;
}

.section-title .bar {
  height: 5px;
  width: 90px;
  background: #fbd1a6;
  margin: 20px auto;
  position: relative;
  border-radius: 30px;
}

.section-title .bar::before {
  content: "";
  position: absolute;
  left: 0;
  top: -2.7px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f7993b;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

#header.is-sticky {
  position: fixed;
  top: 4px;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

#header.is-sticky .startp-nav {
  background-color: #ffffff !important;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding: 0;
}

.startp-mobile-nav {
  display: none;
}

.startp-mobile-nav.mean-container .mean-nav ul li a.active {
  color: #2e70a6;
}

.startp-nav {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
}

.navbar-toggler {
  padding: 3px 8px;
  font-size: 18px;
}

.startp-nav.p-relative {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.startp-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.startp-nav nav .navbar-nav .nav-item {
  position: relative;
  padding: 15px 0;
}

.startp-nav nav .navbar-nav .nav-item a {
  font-weight: 500;
  font-size: 14px;
  color: #0e314c;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
  text-transform: uppercase;
}

.startp-nav nav .navbar-nav .nav-item a:hover,
.startp-nav nav .navbar-nav .nav-item a:focus,
.startp-nav nav .navbar-nav .nav-item a.active {
  color: #f7993b;
}

.startp-nav nav .navbar-nav .nav-item a svg {
  width: 14px;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  position: absolute;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
  opacity: 0;
  border-radius: 5px;
  visibility: hidden;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li {
  position: relative;
  padding: 0;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a {
  font-size: 13px;
  font-weight: 500;
  padding: 6px 15px;
  margin: 0;
  display: block;
  color: #4a6f8a;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a:hover,
.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a:focus,
.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a.active {
  color: #f7993b !important;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu {
  position: absolute;
  left: 100%;
  top: 0;
  opacity: 0 !important;
  visibility: hidden !important;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a {
  color: #4a6f8a;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a:hover,
.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a:focus,
.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a.active {
  color: #f7993b;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li:hover .dropdown_menu {
  opacity: 1 !important;
  visibility: visible !important;
  top: -20px !important;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li:hover a {
  color: #2882c4;
}

.startp-nav nav .navbar-nav .nav-item:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.startp-nav nav .others-option {
  margin-left: 20px;
}

.startp-nav nav .others-option .btn.btn-light {
  background: transparent;
  border: 2px dashed #c8e6fd;
  padding: 12px 33px;
  margin-right: 10px;
}

.startp-nav nav .others-option .btn.btn-light:hover,
.startp-nav nav .others-option .btn.btn-light:focus {
  color: #ffffff;
  border-color: #2e70a6;
}

.startp-nav nav .others-option .btn.btn-light::after,
.startp-nav nav .others-option .btn.btn-light::before {
  background: #f7993b;
}

.startp-nav nav .others-option .btn.btn-primary {
  background: #f7993b;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.startp-nav nav .others-option .btn.btn-primary::after,
.startp-nav nav .others-option .btn.btn-primary::before {
  background: #2e70a6;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.startp-nav nav .navbar-nav .nav-item a i-feather {
  width: auto;
  height: auto;
}

.industriessectionmain button.slick-prev.slick-arrow {
  position: absolute;
  top: -65px !important;
  left: 15px;
  margin: 0 auto;
  margin-top: 0px;
  z-index: 9999;
}

.industriessectionmain .slick-prev:before {
  content: "←";
  color: #000 !important;
  background-repeat: no-repeat !important;
  height: 41px;
}

.industriessectionmain button.slick-next.slick-arrow {
  position: absolute;
  top: -50px !important;
  left: -55%;
  margin: 0 auto;
  margin-top: 0px;
  z-index: 9999;
  width: 73%;
}

.brandSlide .slick-next {
  height: 30px !important;
  width: 30px !important;
}

.brandSlide .slick-next {
  right: -55px;
}

.brandSlide .slick-prev {
  height: 30px !important;
  width: 30px !important;
}

.brandSlide .slick-prev {
  left: -55px;
}

.industriessectionmain .slick-next:before {
  content: "→";
  color: #000 !important;
  background-repeat: no-repeat !important;
  height: 41px;
}

.industriessectionmain .slick-prev:before {
  font-family: unset;
  font-size: unset;
  line-height: unset;
  color: unset;
  opacity: unset;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}

.industriessectionmain .slick-next:before {
  font-family: unset;
  font-size: unset;
  line-height: unset;
  color: unset;
  opacity: unset;
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}

.teamSlider .slick-prev {
  width: 35px;
  height: 35px;
}

.teamSlider .slick-next {
  width: 35px;
  height: 35px;
}

.glimpse_slider .carousel-control-next-icon {
  width: 30px;
  height: 30px;
  background: #F7993B;
  border-radius: 50%;
  position: relative;
}

.glimpse_slider .carousel-control-next-icon::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 2px solid white;
  border-bottom: 0px;
  border-left: 0px;
  top: 10px;
  right: 12px;
  transform: rotate(45deg);
}

.glimpse_slider .carousel-control-prev-icon {
  width: 30px;
  height: 30px;
  background: #F7993B;
  border-radius: 50%;
  position: relative;
}

.glimpse_slider .carousel-control-prev-icon::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 2px solid white;
  border-top: 0px;
  border-right: 0px;
  top: 10px;
  left: 12px;
  transform: rotate(45deg);
}

.blog-fulltxt h2 {
  font-size: 24px;
  margin: 20px 0px;
  font-weight: 600;
}

.blog-fulltxt h4 {
  font-size: 16px;
}

.blog-fulltxt h5 {
  font-size: 17px;
}

.blog-fulltxt p {
  margin-bottom: 20px !important;
}

.blog-fulltxt tbody,
td,
tfoot,
th,
thead,
tr {
  border: 1px solid #e3d8d8;
  padding: 10px;
}

.blog-fulltxt table {
  margin: 20px 0px;
}

.clientSlider2 .slick-dots li {
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.clientSlider2 .slick-dots li button:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: none;
}

.clientSlider2 .slick-dots li.slick-active {
  width: 25px;
  height: 10px;
  border-radius: 10px;
  background: #2d6fa6;
}

.clientSlider2 .slick-dots li.slick-active button {
  display: none;
}

.clientSlider2 .slick-dots li button {
  display: none;
}

.clientSlider2 .slick-dots li.slick-active button:before {
  content: "" !important;
  width: 25px;
  height: 10px;
  border-radius: 10px;
  background: #2d6fa6;
  display: none;
}

.clientSlider2 .slick-dots li button:before {
  line-height: 10px;
}


.blogslider .carousel-control-prev,
.blogslider .carousel-control-next {
  display: none;
}


@media only screen and (max-width: 1250px) and (min-width: 768px) {
  .industriessectionmain button.slick-prev.slick-arrow {
    top: -50px !important;
  }

  .industriessectionmain button.slick-next.slick-arrow {
    top: -30px !important;
  }

  .faq-list {
    min-height: 90px !important;
  }

  .cardSlider span.carousel-control-prev-icon {
    left: -40px;
  }

  .cardSlider span.carousel-control-next-icon {
    right: -40px;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 1500px) {
  .mainheading {
    font-size: 25px;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    font-size: 14px;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    margin: 0px 15px;
  }
}

@media only screen and (max-width: 1360px) {
  .blog-fulltxt h2 {
    font-size: 20px;
    margin: 10px 0px;
    font-weight: 600;
  }

  .blog-fulltxt h4 {
    font-size: 16px;
  }

  .blog-fulltxt h5 {
    font-size: 16px;
  }

  .blog-fulltxt p {
    margin-bottom: 20px !important;
  }

  .startp-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 14px;
    color: #0e314c;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
}

@media only screen and (max-width: 767px) {


  .glimpse_slider .carousel-control-next-icon {
    width: 50px;
    height: 50px;
    right: 25px;
    top: 250px;
  }

  .glimpse_slider .carousel-control-next-icon::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 2px solid white;
    border-bottom: 0px;
    border-left: 0px;
    top: 20px;
    right: 30px;
    transform: rotate(45deg);
  }


  .glimpse_slider .carousel-control-prev-icon {
    width: 50px;
    height: 50px;
    left: 25px;
    top: 250px;
  }

  .glimpse_slider .carousel-control-prev-icon::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 2px solid white;
    border-top: 0px;
    border-right: 0px;
    top: 20px;
    left: 30px;
    transform: rotate(45deg);
  }



  .cardSlider .carousel-control-prev {
    left: 40px;
    top: 45%;
  }

  .cardSlider .carousel-control-next {
    right: 40px;
    top: 45%;
  }

  .cardSlider span.carousel-control-prev-icon {
    background: #ff8000;
    position: absolute;
    width: 50px;
    left: -75px;
    border-radius: 50%;
    height: 50px;
  }

  .cardSlider span.carousel-control-prev-icon::after {
    top: 18px;
  }

  .cardSlider span.carousel-control-next-icon {
    background: #ff8000;
    position: absolute;
    right: -75px;
    width: 50px;
    border-radius: 50%;
    height: 50px;
  }

  .cardSlider span.carousel-control-next-icon::after {
    top: 18px;
  }




  .clientSlider2 .slick-dots {
    right: 0% !important;
  }

  .clientSlider button.slick-next.slick-arrow {
    display: none !important;
  }

  .blog-fulltxt tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: 1px solid #e3d8d8;
    padding: 5px;
    font-size: 12px;
  }

  .blog-fulltxt h2 {
    margin: 20px 0px;
  }

  .blog-fulltxt ol {
    padding-left: 15px !important;
  }

  .blog-fulltxt ul {
    padding-left: 15px !important;
  }

  .blog-fulltxt p {
    margin-bottom: 20px !important;
  }

  .section-title h2 {
    margin-top: 0px;
  }

  p.aboutContent {
    font-size: 13px;
    text-align: center;
  }

  .home-main-banner {
    padding-bottom: 0px !important;
  }

  .righttopsliderbox .owl-item .col-lg-12 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .career-main-banner {
    padding-bottom: 0px !important;
  }

  .mainheading {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.2;
    width: 100%;
  }

  .slick-dots li.slick-active button:before {
    color: #f7993b !important;
  }

  .teamSlider .slick-prev {
    width: 20px;
    height: 20px;
  }

  .teamSlider .slick-next {
    width: 20px;
    height: 20px;
  }
}

.main-banner {
  position: relative;
  height: 750px;
  z-index: 1;
  margin-top: 0px;
}

.main-banner .hero-content h1 {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #f7993b;
}

.main-banner .hero-content h1 span {
  font-family: "Satisfy", cursive;
  color: #2e70a6;
}

.main-banner .hero-content p {
  margin-bottom: 30px;
  font-size: 15px;
}

.main-banner .banner-form {
  background: #ffffff;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 30px;
  border-radius: 5px;
}

.main-banner .banner-form form .form-control {
  padding-left: 15px;
}

.main-banner .banner-form form .btn {
  display: block;
  width: 100%;
  -webkit-transform: unset;
  transform: unset;
  margin-top: 25px;
}

.main-banner .banner-image {
  position: relative;
}

.main-banner .banner-image img {
  position: absolute;
}

.main-banner .banner-image img:nth-child(1) {
  z-index: 3;
  top: -190px;
  left: -45px;
}

.main-banner .banner-image img:nth-child(2) {
  right: 145px;
  top: -270px;
}

.main-banner .banner-image img:nth-child(3) {
  left: 25px;
  top: -20px;
}

.main-banner .banner-image img:nth-child(4) {
  right: 12%;
  top: 118px;
  z-index: 1;
}

.main-banner .banner-image img:nth-child(5) {
  left: 28%;
  top: -120px;
  z-index: 2;
}

.main-banner .banner-image img:nth-child(6) {
  right: 35%;
  z-index: 1;
  top: -150px;
}

.main-banner .banner-image img:nth-child(7) {
  right: 20px;
  top: -110px;
}

.main-banner .banner-image img:nth-child(8) {
  right: 25px;
  top: -140px;
}

.main-banner .banner-image img:nth-child(9) {
  right: 30%;
  z-index: 1;
  top: -35px;
}

.main-banner .banner-image img:nth-child(10) {
  left: 37%;
  top: -28px;
  z-index: 1;
}

.main-banner .banner-image img:nth-child(11) {
  left: 37%;
  top: -160px;
  z-index: 1;
}

.main-banner .banner-image img:nth-child(12) {
  left: 90px;
  top: -110px;
}

.main-banner .banner-image img:nth-child(13) {
  left: 56%;
  top: -25px;
  z-index: 2;
}

.main-banner .banner-image img:nth-child(14) {
  right: 105px;
  top: -28px;
}

.main-banner .banner-image img:last-child {
  display: block;
}

.main-banner .banner-image.single-banner-image img {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hosting-main-banner {
  height: 650px;
  position: relative;
  z-index: 1;
  background-color: #032b8d;
}

.hosting-main-banner .hosting-banner-content h1 {
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 25px;
}

.hosting-main-banner .hosting-banner-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.hosting-main-banner .hosting-banner-content ul li {
  margin-bottom: 12px;
  color: #ffffff;
  position: relative;
  padding-left: 30px;
}

.hosting-main-banner .hosting-banner-content ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #2882c4;
  content: "";
}

.hosting-main-banner .hosting-banner-content ul li:last-child {
  margin-bottom: 0;
}

.hosting-main-banner .hosting-banner-content p {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 30px;
  margin-top: 25px;
}

.hosting-main-banner .hosting-banner-content p span {
  text-decoration: line-through;
}

.hosting-main-banner .hosting-banner-image {
  position: relative;
}

.hosting-main-banner .hosting-banner-image img {
  position: absolute;
}

.hosting-main-banner .hosting-banner-image img:nth-child(1) {
  top: -175px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(2) {
  top: 184px;
  left: 21%;
}

.hosting-main-banner .hosting-banner-image img:nth-child(3) {
  top: 177px;
  right: 38px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(4) {
  left: 29%;
  right: 0;
  top: -15px;
  margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(5) {
  right: 8px;
  top: -107px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(6) {
  left: 18%;
  top: -175px;
  z-index: -1;
}

.hosting-main-banner .hosting-banner-image img:nth-child(7) {
  top: -235px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(8) {
  left: 27%;
  top: -200px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(9) {
  z-index: -2;
  top: 40px;
  left: 80px;
  right: 0;
  margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(10) {
  display: none;
}

.hosting-main-banner .hosting-banner-image.single-hosting-banner-image img {
  display: block;
  position: relative;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0;
}

.shape1 {
  position: absolute;
  top: 20%;
  z-index: -1;
  left: 30px;
  -webkit-animation: rotate3d 4s linear infinite;
  animation: rotate3d 4s linear infinite;
  opacity: 0.4;
}

.shape2 {
  position: absolute;
  z-index: -1;
  top: 60%;
  left: 15%;
  width: 22px;
}

.shape3 {
  position: absolute;
  left: 25%;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 15s infinite linear;
  animation: animationFramesOne 15s infinite linear;
}

.shape4 {
  position: absolute;
  right: 25%;
  bottom: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 20s infinite linear;
  animation: animationFramesOne 20s infinite linear;
  width: 22px;
}

.shape5 {
  position: absolute;
  right: 5%;
  top: 10%;
  z-index: -1;
  -webkit-animation: movebounce 5s linear infinite;
  animation: movebounce 5s linear infinite;
  opacity: 0.2;
}

.shape6 {
  position: absolute;
  z-index: -1;
  top: 40%;
  right: 10%;
}

.shape7 {
  position: absolute;
  left: 25%;
  top: 15%;
  z-index: -1;
  -webkit-animation: animationFramesOne 20s infinite linear;
  animation: animationFramesOne 20s infinite linear;
}

.shape8 {
  position: absolute;
  z-index: -1;
  top: 15%;
  right: 10%;
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}


@media only screen and (max-width: 767px) {
  .teamSlider .slick-next {
    right: -20px !important;
  }

  .teamSlider .slick-prev {
    left: -30px !important;
  }

  .partnerSlide .slick-next {
    right: 0% !important;
  }

  .partnerSlide .slick-prev {
    left: -2% !important;
  }

  .main-banner .hero-content p {
    margin-bottom: 0px;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
  }

  .home-boxes-area {
    padding-bottom: 0px !important;
  }

  .processSlider .slick-prev {
    left: 0% !important;
    z-index: 9999;
  }

  .processSlider .slick-next {
    right: 3% !important;
  }
}

.boxes-area {
  position: relative;
  padding-top: 50px;
  padding-bottom: 25px;
  z-index: 1;
}

.hosting-boxes-area {
  background-color: #032b8d;
  margin-top: 0;
}

.hosting-boxes-area .single-box {
  padding-left: 18px;
  padding-right: 18px;
}

.single-box {
  border: 1px dashed #f7993b;
  padding: 20px;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  color: black;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  min-height: 360px;
  margin-bottom: 30px;
}

.single-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background: linear-gradient(135deg, #2e70a6 0%, #f7993b 100%);
  background-color: white;
  color: black;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

.single-box .icon {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 55px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-box .icon img {
  border-radius: 5px;
}

.single-box h3 {
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 12px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-box p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: -6px;
  font-size: 13px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.single-box:hover {
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
  border-color: transparent;
}

.single-box:hover::before {
  opacity: 1;
  visibility: visible;
}

.single-box:hover h3,
.single-box:hover ul li {
  color: white !important;
}

.about-content .section-title {
  text-align: left;
  margin-bottom: 18px;
}

.about-content .section-title .bar {
  margin-right: 0;
  margin-left: 0;
}

.about-content .section-title p {
  margin: 0;
}

.about-inner-area {
  margin-top: 60px;
}

.about-inner-area .about-text h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

.why-choose-us {
  background: transparent url(../../assets/img/banner-bg1.jpg) right top no-repeat;
  position: relative;
  z-index: 1;
  border-top: 1px solid #f5f5f5;
}

.why-choose-us .section-title {
  text-align: left;
}

.why-choose-us .section-title .bar {
  margin-left: 0;
  margin-right: 0;
}

.why-choose-us .section-title p {
  margin-left: 0;
  margin-right: 0;
}

.why-choose-us-image {
  position: relative;
  text-align: center;
}

.why-choose-us-image .main-pic {
  display: none;
}

.why-choose-us-image img:nth-child(1) {
  position: relative;
  left: -20px;
}

.why-choose-us-image img:nth-child(3) {
  position: absolute;
  top: -55px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: -1;
}

.why-choose-us-image.single-why-choose-us-image img {
  display: block;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
}

.col-lg-6 .col-lg-6:nth-child(1) .single-why-choose-us {
  margin-top: 60px;
}

.col-lg-6 .col-lg-6:nth-child(2) .single-why-choose-us {
  margin-top: 0;
}

.col-lg-6 .col-lg-6:nth-child(4) .single-why-choose-us {
  margin-top: -30px;
}

.single-why-choose-us {
  padding: 30px 15px;
  border-radius: 5px;
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
  box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 30px;
}

.single-why-choose-us .icon {
  color: #2882c4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us .icon i::before {
  font-size: 45px;
}

.single-why-choose-us h3 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us p {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-why-choose-us:hover {
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
  border-color: transparent;
}

.single-why-choose-us:hover::before {
  opacity: 1;
  visibility: visible;
}

.single-why-choose-us:hover .icon {
  color: #ffffff;
}

.single-why-choose-us:hover h3,
.single-why-choose-us:hover p {
  color: #ffffff;
}

.services-area {
  overflow: hidden;
}

.services-content .section-title {
  text-align: left;
  margin-bottom: 20px;
}

.services-content .section-title .bar {
  margin-left: 0;
  margin-right: 0;
}

.services-content .section-title p {
  margin: 0;
  font-weight: 400;
}

.services-content .row {
  margin-left: -8px;
  margin-right: -8px;
}

.services-content .row .col-lg-6 {
  padding-left: 8px;
  padding-right: 8px;
}

.services-content .box {
  background-color: #fff;
  position: relative;
  position: relative;
  margin-top: 30px;
  z-index: 1;
  display: block;
  padding: 15px;
  color: #0e314c;
  font-weight: 500;
  border-radius: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
}

.services-content .box:hover {
  cursor: default;
}

.services-content .box::before {
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.services-content .box i-feather {
  width: auto;
  height: auto;
}

.services-content .box .fa {
  height: auto;
  margin-right: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #f7993b;
  transition: 0.5s;
  width: 18px;
  font-size: 18px;
}

.services-content .box svg {
  margin-right: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
  color: #f7993b;
  transition: 0.5s;
  width: 18px;
}

.services-content .box:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.services-content .box:hover::before {
  opacity: 1;
  visibility: visible;
}

.services-content .box:hover svg,
.services-content .box:hover .fa {
  color: #ffffff;
}

.services-right-image {
  position: relative;
}

.services-right-image img {
  position: absolute;
}

.services-right-image img:nth-child(1) {
  left: 22%;
  top: -85px;
  z-index: 1;
}

.services-right-image img:nth-child(2) {
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  top: -52px;
}

.services-right-image img:nth-child(3) {
  left: 44%;
  top: -33px;
  z-index: 1;
}

.services-right-image img:nth-child(4) {
  right: 38%;
  z-index: 1;
  top: -220px;
}

.services-right-image img:nth-child(5) {
  z-index: 1;
  right: 24%;
  top: 8px;
}

.services-right-image img:nth-child(6) {
  left: 29%;
  top: 0;
  z-index: 1;
}

.services-right-image img:nth-child(7) {
  right: 44%;
  top: -78px;
  z-index: 1;
}

.services-right-image img:nth-child(8) {
  right: 30%;
  top: -134px;
  z-index: 1;
}

.services-right-image img:nth-child(9) {
  right: 31%;
  top: -21px;
  z-index: 1;
}

.services-right-image img:nth-child(10) {
  right: 24%;
  top: -80px;
}

.services-right-image img:nth-child(11) {
  left: 27%;
  top: -60px;
  z-index: 2;
}

.services-right-image img:nth-child(12) {
  right: 28%;
  z-index: 1;
  top: -54px;
}

.services-right-image img:nth-child(13) {
  left: 31%;
  top: -105px;
  z-index: 1;
}

.services-right-image img.bg-image {
  top: -262px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.services-right-image img:last-child {
  display: none;
}

.services-right-image.single-right-image img {
  display: block;
}

.services-right-image.single-right-image img:first-child {
  top: -60px;
}

.services-right-image.single-right-image img:last-child {
  position: relative;
  left: 0;
  top: 0;
}

.services-left-image {
  position: relative;
}

.services-left-image img {
  position: absolute;
}

.services-left-image img:nth-child(1) {
  left: 15%;
  top: -98px;
}

.services-left-image img:nth-child(2) {
  left: 0;
  top: -200px;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.services-left-image img:nth-child(3) {
  left: 36%;
  z-index: 12;
  top: -55px;
}

.services-left-image img:nth-child(4) {
  left: 55px;
  top: -38px;
  z-index: 1;
}

.services-left-image img:nth-child(5) {
  z-index: 3;
  right: 26%;
  top: -66px;
}

.services-left-image img:nth-child(6) {
  right: 19%;
  top: -41px;
  z-index: 2;
}

.services-left-image img:nth-child(7) {
  right: 97px;
  top: 40px;
  z-index: 1;
}

.services-left-image img:nth-child(8) {
  right: 23%;
  top: -175px;
  z-index: 2;
}

.services-left-image img.bg-image {
  top: -262px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.services-left-image img:last-child {
  display: none;
}

.services-left-image.single-left-image img {
  display: block;
}

.services-left-image.single-left-image img:first-child {
  top: -60px;
}

.services-left-image.single-left-image img:last-child {
  position: relative;
  left: 0;
  top: 0;
}

.single-services-box {
  position: relative;
  background: #ffffff;
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-services-box h3 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.single-services-box .icon {
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #c8e6fd;
  border-radius: 50%;
  color: #2882c4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-services-box .icon.bg-c679e3 {
  color: #3fc6f1;
  background: #edc3fc;
}

.single-services-box .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.3);
}

.single-services-box::before,
.single-services-box::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  background: #2882c4;
  opacity: 0;
  visibility: hidden;
  bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-services-box::before {
  left: 0;
}

.single-services-box::after {
  right: 0;
}

.single-services-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-services-box:hover .icon {
  background: #2882c4;
  color: #ffffff;
}

.single-services-box:hover .icon.bg-c679e3 {
  background: #3fc6f1;
}

.single-services-box:hover .icon.bg-eb6b3d {
  background: #eb6b3d;
}

.single-services-box:hover::after,
.single-services-box:hover::before {
  opacity: 1;
  visibility: visible;
  width: 50%;
}

.services-area-two {
  padding-bottom: 50px;
}

.services-details-desc h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}

.services-details-desc .services-details-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 25px 0 0;
}

.services-details-desc .services-details-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 5px;
}

.services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.services-details-desc .services-details-accordion .accordion .accordion-title {
  display: block;
  padding: 12px 20px 12px 51px;
  text-decoration: none;
  color: #0e314c;
  position: relative;
  border-bottom: 1px solid #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.services-details-desc .services-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #2882c4;
  color: #ffffff;
}

.services-details-desc .services-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
}

.services-details-desc .services-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #e1e1e1;
}

.services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
  content: "\f10f";
}

.services-details-desc .services-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}

.services-details-desc .services-details-accordion .accordion .accordion-content.show {
  display: block;
}

.services-details-area .separate {
  margin-top: 80px;
}

.services-details-image {
  text-align: center;
}

.features-area {
  padding-bottom: 50px;
}

.features-area-two {
  overflow: hidden;
}

.single-features {
  position: relative;
  background: #ffffff;
  padding-left: 95px;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-features h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.single-features .icon {
  position: absolute;
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #c8e6fd;
  border-radius: 50%;
  color: #2882c4;
  left: 20px;
  top: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-features .icon.bg-c679e3 {
  color: #3fc6f1;
  background: #edc3fc;
}

.single-features .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.3);
}

.single-features:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-features:hover .icon {
  background: #2882c4;
  color: #ffffff;
}

.single-features:hover .icon.bg-c679e3 {
  background: #3fc6f1;
}

.single-features:hover .icon.bg-eb6b3d {
  background: #eb6b3d;
}

.hosting-features-area {
  padding-bottom: 50px;
}

.single-hosting-features {
  position: relative;
  background: #ffffff;
  text-align: center;
  padding-left: 20px;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-hosting-features h3 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.single-hosting-features .icon {
  text-align: center;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #c8e6fd;
  display: inline-block;
  border-radius: 50%;
  color: #2882c4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-hosting-features .icon.bg-c679e3 {
  color: #3fc6f1;
  background: #edc3fc;
}

.single-hosting-features .icon.bg-eb6b3d {
  color: #eb6b3d;
  background: rgba(235, 107, 61, 0.3);
}

.single-hosting-features::before,
.single-hosting-features::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  background: #2882c4;
  opacity: 0;
  visibility: hidden;
  bottom: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-hosting-features::before {
  left: 0;
}

.single-hosting-features::after {
  right: 0;
}

.single-hosting-features:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-hosting-features:hover .icon {
  background: #2882c4;
  color: #ffffff;
}

.single-hosting-features:hover .icon.bg-c679e3 {
  background: #3fc6f1;
}

.single-hosting-features:hover .icon.bg-eb6b3d {
  background: #eb6b3d;
}

.single-hosting-features:hover::after,
.single-hosting-features:hover::before {
  opacity: 1;
  visibility: visible;
  width: 50%;
}

div.protipbox {
  background: #f7f7f7d1;
  padding: 40px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  clear: both;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgb(154 161 171 / 40%);
}

.protipimg {
  width: 100%;
  max-width: 65px;
  min-width: 65px;
  margin: 0 auto;
}

.features-details-desc h3 {
  font-size: 20px;
  margin-bottom: 13px;
  font-weight: 600;
}

.features-details-desc .features-details-list {
  padding: 0;
  margin: 20px 0 0;
  list-style-type: none;
}

.features-details-desc .features-details-list li {
  margin-bottom: 12px;
  color: #6084a4;
  position: relative;
  padding-left: 15px;
}

.features-details-desc .features-details-list li::before {
  background: #2882c4;
  width: 8px;
  height: 8px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  content: "";
}

.features-details-desc .features-details-list li:last-child {
  margin-bottom: 0;
}

.features-details-desc .features-details-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 25px 0 0;
}

.features-details-desc .features-details-accordion .accordion .accordion-item {
  display: block;
  -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: #ffffff;
  margin-bottom: 5px;
}

.features-details-desc .features-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.features-details-desc .features-details-accordion .accordion .accordion-title {
  display: block;
  padding: 12px 20px 12px 51px;
  text-decoration: none;
  color: #0e314c;
  position: relative;
  border-bottom: 1px solid #ffffff;
  font-size: 15px;
  font-weight: 500;
}

.features-details-desc .features-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #2882c4;
  color: #ffffff;
}

.features-details-desc .features-details-accordion .accordion .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
}

.features-details-desc .features-details-accordion .accordion .accordion-title.active {
  border-bottom-color: #e1e1e1;
}

.features-details-desc .features-details-accordion .accordion .accordion-title.active i::before {
  content: "\f10f";
}

.features-details-desc .features-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  padding: 15px 15px;
}

.features-details-desc .features-details-accordion .accordion .accordion-content.show {
  display: block;
}

.features-details-area .separate {
  margin-top: 80px;
}

.features-details-image {
  text-align: center;
}

.team-area .owl-controls {
  margin-bottom: 30px;
}

.single-team {
  background: #ffffff;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
  text-align: center;
}

.single-team .team-image {
  position: relative;
  z-index: 1;
  padding-left: 35px;
  padding-right: 35px;
}

.single-team .team-image img {
  border: 3px solid #c8e6fd;
  border-radius: 100%;
  display: inline-block !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 150px !important;
  height: 150px !important;
}

.single-team .team-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background: #f9f6f6;
  height: 10px;
  width: 100%;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-team .team-content {
  padding-left: 35px;
  padding-right: 35px;
}

.single-team .team-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-team .team-content ul li {
  display: inline-block;
  margin: 0 3px;
}

.single-team .team-content ul li a {
  color: #2e70a6;
}

.single-team .team-content ul li a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  color: #2882c4;
}

.single-team .team-content ul li a svg {
  width: 19px;
}

.single-team .team-content p {
  margin-top: 20px;
  margin-bottom: 0;
}

.single-team .team-info {
  background: linear-gradient(135deg, #2e70a6 0%, #2e70a6 100%);
  padding: 10px 15px;
  border-radius: 75px;
  margin-bottom: 25px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-team .team-info h3 {
  font-size: 15px;
  margin-bottom: 5px;
  color: #ffffff;
}

.single-team .team-info span {
  color: #ffffff;
  font-size: 13px;
}

.single-team:hover {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-team:hover .team-image::before {
  height: 0;
}

.single-team:hover .team-image img {
  border-color: #2882c4;
}

.single-team:hover .team-info {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
}

.works-area {
  position: relative;
  z-index: 1;
  padding-bottom: 0px;
}

.single-works {
  position: relative;
  margin-bottom: 30px;
}

.single-works::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.single-works .icon {
  position: absolute;
  z-index: 5;
  border-radius: 50%;
  top: 0;
  right: 45px;
  background: #ffffff;
  width: 45px;
  text-align: center;
  height: 45px;
  line-height: 42px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
}

.single-works .icon svg {
  width: 19px;
}

.single-works .icon:hover {
  background: #0e314c;
  color: #ffffff;
}

.single-works .works-content {
  position: absolute;
  bottom: -60px;
  left: 0;
  padding: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.single-works .works-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
}

.single-works .works-content h3 a {
  color: #ffffff;
}

.single-works .works-content p {
  color: #ffffff;
  display: -webkit-box;
  height: 80px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-works:hover::before {
  opacity: 0.95;
  visibility: visible;
}

.single-works:hover .icon {
  opacity: 1;
  visibility: visible;
  top: 45px;
}

.single-works:hover .works-content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.funfacts-area {
  position: relative;
  z-index: 1;
}

.funfacts-area .section-title h2 {
  max-width: 710px;
  margin: 0 auto;
}

.funfacts-area .map-bg {
  position: absolute;
  z-index: -1;
  top: 120px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
}

.funfact {
  text-align: center;
}

.funfact-imgdiv {
  width: 100%;
  max-width: 80px;
  margin: 0 auto;
}

.funfact-imgdiv img {
  width: 100%;
}

.funfact h3 {
  color: #ffffff;
  margin-bottom: 7px;
  font-weight: 600;
  background: linear-gradient(135deg, #2e70a6 0%, #f7993b 100%);
  display: inline-block;
  margin-bottom: 7px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 3px 10px;
}

.funfact p {
  line-height: initial;
}

.contact-cta-box {
  margin: 80px auto 0;
  max-width: 600px;
  border: 1px dashed #ebebeb;
  padding: 30px 210px 30px 30px;
  border-radius: 5px;
  position: relative;
}

.contact-cta-box h3 {
  font-size: 20px;
  margin-bottom: 6px;
}

.contact-cta-box p {
  margin-bottom: 0;
}

.contact-cta-box .btn {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -27px;
}

.contact-cta-box .btn::before,
.contact-cta-box .btn::after {
  background: #2882c4;
}

.contact-cta-box .btn-primary {
  background: #f79a3e;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.contact-cta-box .btn-primary:hover,
.contact-cta-box .btn-primary:focus {
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.pricing-area {
  position: relative;
  z-index: 1;
}

.pricing-table {
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding-bottom: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.pricing-table .pricing-header {
  background: #ffffff;
  position: relative;
  padding: 30px 20px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #eeeeee;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 30px;
  z-index: 1;
}

.pricing-table .pricing-header::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px 5px 0 0;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: -1;
}

.pricing-table .pricing-header h3 {
  font-size: 18px;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 0;
  font-weight: 600;
}

.pricing-table .price {
  margin-bottom: 20px;
}

.pricing-table .price span {
  color: #2882c4;
  font-size: 30px;
  font-weight: 600;
}

.pricing-table .price span sup {
  font-weight: 500;
  margin: 0px 5px 0 0px;
  top: -8px;
}

.pricing-table .price span span {
  font-size: 13px;
  font-weight: 500;
}

.pricing-table .pricing-features ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 20px;
}

.pricing-table .pricing-features ul li {
  background: #ffffff;
  color: #b9b9b9;
  text-decoration: line-through;
  border-top: 1px dashed #eee;
  padding: 10px 0;
}

.pricing-table .pricing-features ul li:last-child {
  margin-bottom: 0;
}

.pricing-table .pricing-features ul li.active {
  color: #6084a4;
  text-decoration: none;
}

.pricing-table:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.pricing-table:hover .pricing-header::before {
  opacity: 1;
  visibility: visible;
}

.pricing-table:hover .pricing-header h3 {
  color: #ffffff;
}

.pricing-table.active-plan .pricing-header::before {
  opacity: 1;
  visibility: visible;
}

.pricing-table.active-plan .pricing-header h3 {
  color: #ffffff;
}

.pricing-table.active-plan .btn-primary {
  background: #3fc6f1;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.pricing-table.active-plan .btn-primary::after,
.pricing-table.active-plan .btn-primary::before {
  background: #2882c4;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.single-pricing-table {
  text-align: center;
  background: #ffffff;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 35px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-pricing-table .pricing-header i {
  transition: 0.5s;
  border: 1px solid #eee;
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
}

.single-pricing-table .pricing-header i::before {
  font-size: 45px;
}

.single-pricing-table .pricing-header h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 20px 0;
}

.single-pricing-table .price {
  margin-bottom: 20px;
}

.single-pricing-table .price span {
  color: #2882c4;
  font-size: 30px;
  font-weight: 600;
}

.single-pricing-table .price span sup {
  font-weight: 500;
}

.single-pricing-table .price span span {
  font-size: 14px;
  font-weight: 500;
}

.single-pricing-table .pricing-features ul {
  padding: 0;
  margin: 0 0 30px;
  list-style-type: none;
}

.single-pricing-table .pricing-features ul li {
  color: #6084a4;
  margin-bottom: 12px;
}

.single-pricing-table .pricing-features ul li:last-child {
  margin-bottom: 0;
}

.single-pricing-table .pricing-features ul li svg {
  width: 15px;
}

.single-pricing-table.active-plan .btn-primary {
  background: #3fc6f1;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.single-pricing-table.active-plan .btn-primary::after,
.single-pricing-table.active-plan .btn-primary::before {
  background: #2882c4;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.domain-search-content {
  text-align: center;
  max-width: 750px;
  margin: 0 auto;
}

.domain-search-content h2 {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.domain-search-content form {
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 30px;
}

.domain-search-content form .form-control {
  background-color: #ffffff;
  border: none;
  height: 55px;
  padding-left: 20px;
  float: left;
  width: 63%;
}

.domain-search-content form .domain-select {
  float: left;
  width: 15%;
  padding-right: 20px;
}

.domain-search-content form .domain-select .form-control {
  padding: 0;
  float: unset;
  width: 100%;
  cursor: pointer;
  height: 55px;
}

.domain-search-content form button {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  float: left;
  width: 22%;
  height: 55px;
  border-radius: 30px;
  background-color: #2882c4;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: 15px 40px;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.domain-search-content form button:hover,
.domain-search-content form button:focus {
  background-color: #3fc6f1;
}

.domain-search-content ul {
  padding: 0;
  margin: 30px 0 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.domain-search-content ul li {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: 500;
  border-left: 1px solid #eeeeee;
  color: #6084a4;
}

.domain-search-content ul li:first-child {
  border-left: none;
}

.feedback-area {
  position: relative;
  z-index: 1;
}

.feedback-slides {
  position: relative;
  max-width: 750px;
  margin: 0 auto;
}

.feedback-slides .client-feedback {
  position: relative;
}

.client-feedback .slick-slide:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .single-box {
    min-height: 270px;
  }

  .feedback-slides .client-feedback .single-feedback {
    min-height: 350px;
  }

  .industriessectionmain button.slick-next.slick-arrow {
    left: -60%;
  }

  .single-box::before {
    background: unset;
  }

  .single-box:hover h3,
  .single-box:hover ul li {
    color: black !important;
  }
}

.feedback-slides .client-feedback .single-feedback {
  background: #ffffff;
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
}

.feedback-slides .client-feedback .single-feedback .quote-icon {
  margin: 0 auto 30px;
  max-width: 60px;
}

.feedback-slides .client-feedback .single-feedback .client-img-div {
  display: flex;
  width: 100%;
  text-align: left;
  max-width: 315px;
  margin: 0 auto;
}

.feedback-slides .client-feedback .single-feedback .client-img-img {
  border-radius: 50%;
  border: 2px solid #2882c4;
  padding: 3px;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.client-ctydiv {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.client-ctydiv img {
  width: 31px !important;
  margin-top: 5px;
  height: 20px !important;
  margin-right: 12px !important;
  box-shadow: 0 0 #00000024, 0 0 #0000001f, 0 0 #0003 !important;
}

.client-ctydiv p {
  margin-bottom: 0 !important;
}

.feedback-slides .client-feedback .single-feedback h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.feedback-slides .client-feedback .single-feedback span {
  display: block;
  margin-top: 5px;
  color: #2d6fa6;
  font-size: 13px;
}

.feedback-slides .client-feedback .single-feedback p {
  font-size: 15px;
  margin-bottom: 30px;
  padding-left: 5px;
}

.feedback-slides .client-thumbnails {
  position: relative;
  margin: auto;
  max-width: 350px;
}

.progress-bar {
  background-color: #2fd955;
}

@media only screen and (max-width: 767px) {
  .feedback-slides .client-thumbnails {
    max-width: 255px !important;
  }
}

.client-thumbnails .slick-slide:focus {
  outline: none;
}

.feedback-slides .client-thumbnails .item .img-fill {
  cursor: pointer;
  position: relative;
  text-align: center;
}

.feedback-slides .client-thumbnails .item .img-fill:focus {
  outline: 0;
}

.feedback-slides .client-thumbnails .item .img-fill img {
  opacity: 0.4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: inline-block;
  position: relative;
  border: 2px solid #2882c4;
  border-radius: 50%;
  padding: 3px;
  width: 60px;
}

.feedback-slides .client-thumbnails .item:hover .img-fill img,
.feedback-slides .client-thumbnails .item.slick-center .img-fill img {
  opacity: 1;
}

.client-thumbnails .next-arrow,
.client-thumbnails .prev-arrow {
  position: absolute;
  width: 35px;
  height: 35px;
  cursor: pointer;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: transparent;
  color: #5e5e5e;
  border: 2px solid #5e5e5e;
  z-index: 1;
  border-radius: 50%;
  line-height: 25px;
  outline: 0 !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.client-thumbnails .next-arrow i-feather,
.client-thumbnails .prev-arrow i-feather {
  width: auto;
  height: auto;
}

.client-thumbnails .next-arrow::before,
.client-thumbnails .prev-arrow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
}

.client-thumbnails .next-arrow:hover,
.client-thumbnails .prev-arrow:hover {
  color: #ffffff;
  border-color: #2882c4;
}

.client-thumbnails .next-arrow:hover::before,
.client-thumbnails .prev-arrow:hover::before {
  opacity: 1;
  visibility: visible;
}

.client-thumbnails .next-arrow svg,
.client-thumbnails .prev-arrow svg {
  width: 18px;
  height: 18px;
  position: relative;
}

.client-thumbnails .next-arrow {
  right: -45px;
}

.client-thumbnails .prev-arrow {
  left: -45px;
}

.testimonials-slides {
  background-color: #f7fbfe;
  max-width: 850px;
  margin: 90px auto 30px;
  position: relative;
  z-index: 1;
}

.testimonials-slides::before {
  position: absolute;
  left: -30px;
  top: -30px;
  width: 260px;
  height: 260px;
  content: "";
  z-index: -1;
  background-image: url(../../assets/img/layer.png);
}

.testimonials-slides::after {
  position: absolute;
  right: -30px;
  bottom: -30px;
  width: 260px;
  height: 260px;
  content: "";
  z-index: -1;
  background-image: url(../../assets/img/layer.png);
}

.testimonials-slides.owl-theme .owl-nav.disabled+.owl-dots {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  margin-top: 0;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  background: transparent;
  border: 2px solid #d6d6d6;
  position: relative;
  margin: 0 3px;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span::before {
  background: #d6d6d6;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span,
.testimonials-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: #2882c4;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span::before,
.testimonials-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background: #2882c4;
}

.single-feedback-item {
  padding: 40px 130px 90px;
  text-align: center;
  background-color: #f7fbfe;
  position: relative;
}

.single-feedback-item .client-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: left;
  max-width: 280px;
  margin: 0 auto 25px;
}

.single-feedback-item .client-info .image {
  padding-right: 20px;
}

.single-feedback-item .client-info .image img {
  display: inline-block !important;
  width: 75px;
  border-radius: 50%;
  border: 3px solid #2882c4;
}

.single-feedback-item .client-info .title h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.single-feedback-item .client-info .title span {
  display: block;
  margin-top: 5px;
  color: #3fc6f1;
  font-size: 13px;
}

.single-feedback-item p {
  font-size: 16px;
  font-style: italic;
}

.single-feedback-item::before {
  content: "\f11b";
  color: #d9dcde;
  font-family: Flaticon;
  font-size: 30px;
  font-style: normal;
  font-size: 65px;
  position: absolute;
  top: 0;
  left: 25px;
}

.ready-to-talk {
  text-align: center;
  position: relative;
  padding-top: 50px;
  padding-bottom: 140px;
}

.ready-to-talk h3 {
  font-size: 24px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 600;
}

.ready-to-talk p {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 16px;
}

.ready-to-talk .btn::before,
.ready-to-talk .btn::after {
  background: #2882c4;
}

.ready-to-talk .btn-primary {
  background: #2e70a6;
  -webkit-box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);
  box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);
}

.ready-to-talk .btn-primary:hover,
.ready-to-talk .btn-primary:focus {
  -webkit-box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
  box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
}

.ready-to-talk span a {
  margin-left: 15px;
  color: #ffffff;
  text-decoration: underline;
}

.ready-to-talk span a:hover {
  text-decoration: none;
}

.partner-section {
  text-align: center;
  position: relative;
  margin-top: -120px;
  background: transparent;
}

.partner-section h5 {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 0;
}

.partner-section .partner-inner {
  background: #ffffff;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 65px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  margin-top: 25px;
}

.partner-inner-para {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 25px;
  color: #949494;
  text-align: center;
}

.partner-section .partner-inner a {
  display: block;
  margin-top: 25px;
  position: relative;
}

.partner-section .partner-inner a img:last-child {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 767px) {
  .partner-section .partner-inner {
    padding-bottom: 30px;
  }

  .partner-section .partner-inner a img:last-child {
    display: none;
  }
}

.partner-section .partner-inner a:hover img:last-child {
  opacity: 0;
  top: 0;
  visibility: visible;
}

.blog-area {
  padding-bottom: 50px;
}

.blog-area .pagination-area {
  margin-bottom: 30px;
}

.single-blog-post {
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}

.single-blog-post .blog-image {
  position: relative;
}

.single-blog-post .blog-image a {
  display: block;
}

.single-blog-post .blog-image .date {
  position: absolute;
  left: 20px;
  bottom: 15px;
  background: linear-gradient(135deg, #f79a3c 0%, #2e70a6 100%);
  color: #ffffff;
  padding: 8px 16px;
  font-size: 12px;
  border-radius: 50px;
}

.single-blog-post .blog-image .date i-feather {
  width: auto;
  height: auto;
}

.single-blog-post .blog-image .date svg {
  width: 15px;
  margin-right: 5px;
  margin-top: -4px;
}

.single-blog-post .blog-post-content {
  padding-top: 15px;
  padding-left: 20px;
  padding-bottom: 15px;
  padding-right: 20px;
  background: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #eee;
}

.single-blog-post .blog-post-content h3 {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 0px;
  font-weight: 600;
}

.single-blog-post .blog-post-content h3 a {
  display: -webkit-box;
  height: 60px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.single-blog-post .blog-post-content p {
  display: -webkit-box;
  height: 75px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.single-blog-post .blog-post-content span {
  display: block;
  color: #0e314c;
  margin: 15px 0;
}

.single-blog-post .blog-post-content span a {
  color: #6084a4;
}

.single-blog-post .blog-post-content span a:hover {
  color: #2882c4;
}

.single-blog-post .blog-post-content .read-more-btn {
  color: #0e314c;
  font-weight: 600;
  font-size: 13px;
}

.single-blog-post .blog-post-content .read-more-btn svg {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 15px;
}

.single-blog-post .blog-post-content .read-more-btn:hover {
  color: #2882c4;
}

.single-blog-post .blog-post-content .read-more-btn:hover svg {
  margin-left: 5px;
}

.single-blog-post:hover {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-blog-post:hover .blog-post-content {
  background: #ffffff;
}

.woocommerce-topbar {
  background-color: #fcfbfb;
  padding: 10px 40px;
  margin-bottom: 30px;
}

.woocommerce-topbar .woocommerce-topbar-ordering .form-control {
  cursor: pointer;
  background-color: transparent;
  border-color: #ebebeb;
  border-radius: 4px;
}

.single-products {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}

.single-products .products-content {
  margin-top: 20px;
}

.single-products .products-content h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.single-products .products-content ul {
  padding: 0;
  margin: 10px 0 12px;
  list-style-type: none;
}

.single-products .products-content ul li {
  display: inline-block;
  color: #eac11d;
}

.single-products .products-content ul li i::before {
  font-size: 15px;
}

.single-products .products-content .add-to-cart-btn {
  display: block;
  width: 100%;
  color: #0e314c;
  border: 1px solid #0e314c;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.single-products .sale-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff2d2d;
  color: #fff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  z-index: 2;
}

.single-products .products-image {
  overflow: hidden;
  position: relative;
}

.single-products .products-image img {
  -webkit-transition: all 1.5s ease-out;
  transition: all 1.5s ease-out;
  width: 100%;
}

.single-products .products-image::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: gray;
  content: "";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.single-products .products-image ul {
  padding: 0;
  z-index: 2;
  list-style-type: none;
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-products .products-image ul li {
  display: inline-block;
}

.single-products .products-image ul li a {
  display: block;
  background: #ffffff;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.single-products .products-image ul li a svg {
  width: 20px;
}

.single-products .products-image ul li a:hover,
.single-products .products-image ul li a:focus {
  background-color: #2882c4;
  color: #ffffff;
}

.single-products .products-image ul li a:nth-child(1) {
  margin-left: -15px;
}

.single-products .products-image ul li a:nth-child(3) {
  margin-right: -15px;
}

.single-products:hover .products-content .add-to-cart-btn,
.single-products:focus .products-content .add-to-cart-btn {
  background-color: #0e314c;
  color: #ffffff;
}

.single-products:hover .products-image img,
.single-products:focus .products-image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-products:hover .products-image::before,
.single-products:focus .products-image::before {
  opacity: 0.4;
  visibility: visible;
}

.single-products:hover .products-image ul,
.single-products:focus .products-image ul {
  opacity: 1;
  visibility: visible;
}

.single-products:hover .products-image ul li a:nth-child(1),
.single-products:focus .products-image ul li a:nth-child(1) {
  margin-left: 0;
}

.single-products:hover .products-image ul li a:nth-child(3),
.single-products:focus .products-image ul li a:nth-child(3) {
  margin-right: 0;
}

.modal-dialog {
  max-width: 900px;
}

.modal-content {
  border: none;
  border-radius: 0;
}

.modal-content button.close {
  position: absolute;
  right: 2%;
  display: inline-block;
  opacity: 1;
  top: 2%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 32px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.modal-content button.close span {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.modal-content button.close:hover,
.modal-content button.close:focus {
  background-color: #2882c4;
  color: #ffffff;
}

.modal-content button.close:hover span,
.modal-content button.close:focus span {
  color: #ffffff;
}

.modal-content .products-image {
  overflow: hidden;
}

.modal-content .products-image img {
  width: 100%;
}

.modal-content .products-content p {
  margin-bottom: 20px;
}

.modal-content .products-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-content .products-content .price {
  margin-bottom: 15px;
}

.modal-content .products-content .price span {
  text-decoration: line-through;
  color: #6084a4;
}

.modal-content .products-content form .quantity {
  display: inline-block;
}

.modal-content .products-content form .quantity input {
  border: 1px solid #eeeeee;
  background: #ffffff;
  height: 45px;
  padding: 15px;
  width: 75px;
  outline: 0;
  font-size: 20px;
}

.modal-content .products-content form button {
  background: #2882c4;
  color: #ffffff;
  border: none;
  height: 46px;
  padding: 0 20px;
  position: relative;
  top: -3px;
  cursor: pointer;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.modal-content .products-content form button:hover,
.modal-content .products-content form button:focus {
  background-color: #3fc6f1;
  color: #ffffff;
}

.modal-content .products-content .product-meta {
  margin-top: 15px;
}

.modal-content .products-content .product-meta span {
  display: inline-block;
  margin-right: 8px;
}

.products-details-image img {
  width: 100%;
}

.products-details-image .slick-slider {
  position: relative;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.products-details-image .slick-dots {
  padding: 0;
  text-align: center;
  margin: 10px 0 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.products-details-image .slick-dots li {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 5px;
}

.products-details-image .slick-dots li button {
  padding: 0;
  outline: 0;
  border: none;
  margin: 0;
  cursor: pointer;
}

.products-details-image .slick-dots li:last-child {
  margin-right: 0;
}

.products-details-image .slick-dots li:first-child {
  margin-left: 0;
}

.products-details-image .slick-thumbs {
  display: none;
}

.products-details h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.products-details .price {
  margin-bottom: 15px;
}

.products-details .price span {
  text-decoration: line-through;
  color: #6084a4;
}

.products-details .rating {
  padding: 0;
  margin: 0 0 12px;
  list-style-type: none;
}

.products-details .rating li {
  display: inline-block;
  color: #eac11d;
}

.products-details .rating li i::before {
  font-size: 15px;
}

.products-details .availability {
  margin-bottom: 20px;
}

.products-details .availability span {
  color: #2882c4;
}

.products-details form .quantity {
  display: inline-block;
  padding-right: 10px;
}

.products-details form .quantity span {
  padding-right: 10px;
}

.products-details form .quantity input {
  border: 1px solid #eeeeee;
  background: #ffffff;
  height: 45px;
  padding: 15px;
  width: 75px;
  outline: 0;
  font-size: 20px;
}

.products-details form button {
  background: #2882c4;
  color: #ffffff;
  border: none;
  height: 46px;
  padding: 0 20px;
  position: relative;
  top: -3px;
  cursor: pointer;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.products-details form button:hover,
.products-details form button:focus {
  background-color: #3fc6f1;
  color: #ffffff;
}

.products-details form .add-to-wishlist-btn {
  margin-left: 5px;
  background: transparent;
  color: #0e314c;
  border: 1px solid #eeeeee;
  position: relative;
  top: -3px;
  cursor: pointer;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.products-details form .add-to-wishlist-btn:hover,
.products-details form .add-to-wishlist-btn:focus {
  background-color: #3fc6f1;
  color: #ffffff;
  border-color: #3fc6f1;
}

.products-details form .buy-btn {
  margin-top: 20px;
}

.products-details form .buy-btn .btn-primary {
  background: #3fc6f1;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.products-details form .buy-btn .btn-primary::after,
.products-details form .buy-btn .btn-primary::before {
  background: #2882c4;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.products-details .custom-payment-options {
  margin-top: 20px;
}

.products-details .custom-payment-options span {
  display: block;
  margin-bottom: 10px;
}

.products-details .products-share-social {
  margin-top: 25px;
}

.products-details .products-share-social span {
  display: inline-block;
  margin-right: 10px;
}

.products-details .products-share-social ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-block;
}

.products-details .products-share-social ul li {
  display: inline-block;
}

.products-details .products-share-social ul li a {
  width: 32px;
  text-align: center;
  line-height: 27px;
  height: 32px;
  border: 1px solid #2882c4;
  border-radius: 50%;
  color: #2882c4;
}

.products-details .products-share-social ul li a svg {
  width: 15px;
}

.products-details .products-share-social ul li a.facebook {
  border-color: #4267b2;
  color: #4267b2;
}

.products-details .products-share-social ul li a.facebook:hover {
  background: #4267b2;
  color: #ffffff;
}

.products-details .products-share-social ul li a.twitter {
  border-color: #38a1f3;
  color: #38a1f3;
}

.products-details .products-share-social ul li a.twitter:hover {
  background: #38a1f3;
  color: #ffffff;
}

.products-details .products-share-social ul li a.linkedin {
  border-color: #0077b5;
  color: #0077b5;
}

.products-details .products-share-social ul li a.linkedin:hover {
  background: #0077b5;
  color: #ffffff;
}

.products-details .products-share-social ul li a.instagram {
  border-color: #231f20;
  color: #231f20;
}

.products-details .products-share-social ul li a.instagram:hover {
  background: #231f20;
  color: #ffffff;
}

.products-details .products-share-social ul li a:hover {
  background: #2882c4;
  color: #ffffff;
}

.products-details-tabs {
  margin-top: 40px;
  text-align: center;
}

.products-details-tabs #tabs {
  padding: 0;
  margin: 0 0 30px;
  list-style-type: none;
}

.products-details-tabs #tabs li {
  display: inline-block;
  padding: 12px 50px 8px;
  cursor: pointer;
  margin-right: -4px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #eaeaea;
  font-weight: 500;
}

.products-details-tabs #tabs li.active {
  background-color: #ffffff;
  position: relative;
}

.products-details-tabs #tabs li.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  top: 0;
  left: 0;
  background: #2882c4;
}

.products-details-tabs #tabs li.inactive {
  background-color: #eeeeee;
  padding: 12px 50px;
}

.products-details-tabs .content {
  display: none;
}

.products-details-tabs .content.show {
  display: block;
}

.products-details-tabs .content h3 {
  margin-bottom: 12px;
  font-size: 18px;
}

.products-details-tabs .content .additional-information {
  padding: 0;
  margin: 25px 0 0;
  list-style-type: none;
  text-align: left;
}

.products-details-tabs .content .additional-information li {
  border: 1px solid #eeeeee;
  border-bottom: none;
  padding: 10px 15px;
  color: #6084a4;
}

.products-details-tabs .content .additional-information li:last-child {
  border-bottom: 1px solid #eeeeee;
}

.products-details-tabs .content .additional-information li span {
  display: inline-block;
  width: 45%;
}

.products-details-tabs .content .products-reviews {
  text-align: left;
  max-width: 700px;
  margin: 0 auto;
}

.products-details-tabs .content .products-reviews p {
  margin-bottom: 0;
}

.products-details-tabs .content .products-reviews .review-form {
  margin-top: 20px;
  max-width: 620px;
}

.products-details-tabs .content .products-reviews .review-form p {
  margin-bottom: 0;
}

.products-details-tabs .content .products-reviews .review-form .form-control {
  background: transparent;
}

.products-details-tabs .content .products-reviews .star-source {
  width: 0;
  height: 0;
  margin-top: 5px;
  visibility: hidden;
}

.products-details-tabs .content .products-reviews .star-source svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.15rem;
}

.products-details-tabs .content .products-reviews .star-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 105px;
  margin-bottom: 10px;
}

.products-details-tabs .content .products-reviews .star-rating label {
  cursor: pointer;
}

.products-details-tabs .content .products-reviews .star-rating label .star {
  color: transparent;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.products-details-tabs .content .products-reviews .star-rating label:hover~label .star,
.products-details-tabs .content .products-reviews .star-rating svg.star:hover,
.products-details-tabs .content .products-reviews .star-rating input[name="star"]:focus~label .star,
.products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked~label .star {
  color: #f2b01e;
}

.products-details-tabs .content .products-reviews .star-rating svg {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.15rem;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"] {
  display: inline-block;
  width: 0;
  opacity: 0;
  margin-left: -2px;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked+label {
  -webkit-animation: scaleup 1s;
  animation: scaleup 1s;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked+label .star {
  -webkit-animation: starred 0.5s;
  animation: starred 0.5s;
}

@-webkit-keyframes scaleup {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleup {
  from {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes starred {
  from {
    color: #f2b01e;
  }

  to {
    color: #f2b01e;
  }
}

@keyframes starred {
  from {
    color: #f2b01e;
  }

  to {
    color: #f2b01e;
  }
}

.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  font-weight: 500;
  padding: 15px 0;
  font-size: 15px;
  border: none;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: #6084a4;
  padding-left: 0;
  padding-right: 0;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table table tbody tr td.product-name a {
  color: #6084a4;
  font-weight: 500;
  display: inline-block;
}

.cart-table table tbody tr td.product-name a:hover {
  color: #2882c4;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  margin-left: 25px;
  position: relative;
  top: -1px;
}

.cart-table table tbody tr td.product-subtotal .remove svg {
  width: 19px;
}

.cart-table table tbody tr td.product-quantity .form-control {
  background: transparent;
  width: 80px;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .btn-light {
  background-color: #eaedff;
}

.cart-buttons .btn-light:hover,
.cart-buttons .btn-light:focus {
  color: #ffffff;
}

.cart-buttons .btn-light:hover:not(:disabled):not(.disabled).active,
.cart-buttons .btn-light:hover:not(:disabled):not(.disabled):active,
.cart-buttons .btn-light:hover .show>.btn-light.dropdown-toggle,
.cart-buttons .btn-light:focus:not(:disabled):not(.disabled).active,
.cart-buttons .btn-light:focus:not(:disabled):not(.disabled):active,
.cart-buttons .btn-light:focus .show>.btn-light.dropdown-toggle {
  color: #ffffff;
}

.cart-buttons .btn-light:focus {
  color: #ffffff;
}

.cart-totals {
  background: #ffffff;
  padding: 40px;
  max-width: 600px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin: 45px auto 0;
}

.cart-totals h3 {
  font-size: 18px;
  margin-bottom: 25px;
}

.cart-totals ul {
  padding: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: #0e314c;
  overflow: hidden;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  border-top: none;
}

.cart-totals ul li span {
  float: right;
  color: #6084a4;
}

.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 18px 25px 15px;
  margin-bottom: 35px;
  border-top: 3px solid #2882c4;
}

.user-actions svg {
  color: #2882c4;
  margin-right: 4px;
  width: 16px;
}

.user-actions span {
  display: inline-block;
  color: #2882c4;
}

.user-actions span a {
  font-weight: 500;
  display: inline-block;
  color: #0e314c;
}

.user-actions span a:hover,
.user-actions span a:focus {
  color: #2882c4;
}

.checkout-area .title {
  font-size: 18px;
  margin-bottom: 25px;
}

.billing-details {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 25px;
  border-radius: 5px;
}

.billing-details .form-group label {
  display: block;
  color: #6084a4;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group .form-control {
  background: transparent;
}

.billing-details .form-group select {
  cursor: pointer;
}

.billing-details .form-check {
  margin-bottom: 15px;
}

.billing-details .form-check .form-check-label {
  color: #6084a4;
}

.billing-details .form-check label {
  font-size: 14px;
  font-weight: 400;
}

.order-details {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 25px;
  border-radius: 5px;
}

.order-details .order-table table {
  margin-bottom: 0;
  text-align: left;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  font-weight: 500;
  border-color: #eaedff;
  padding: 15px 20px;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #6084a4;
  border-color: #eaedff;
  font-size: 14px;
}

.order-details .order-table table tbody tr td.product-name a {
  color: #6084a4;
  display: inline-block;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: #2882c4;
}

.order-details .order-table table tbody tr td.order-subtotal span,
.order-details .order-table table tbody tr td.order-shipping span,
.order-details .order-table table tbody tr td.total-price span {
  color: #0e314c;
  font-weight: 500;
}

.order-details .payment-method {
  margin-top: 40px;
  border-top: 1px solid #eaedff;
  padding-top: 35px;
}

.order-details .payment-method p {
  font-size: 14px;
}

.order-details .payment-method p [type="radio"]:checked,
.order-details .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-method p [type="radio"]:checked+label,
.order-details .payment-method p [type="radio"]:not(:checked)+label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  font-weight: 600;
  color: #0e314c;
  position: relative;
  margin-bottom: 8px;
}

.order-details .payment-method p [type="radio"]:checked+label::before,
.order-details .payment-method p [type="radio"]:not(:checked)+label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: #ffffff;
}

.order-details .payment-method p [type="radio"]:checked+label::after,
.order-details .payment-method p [type="radio"]:not(:checked)+label::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2882c4;
  position: absolute;
  top: 7px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.order-details .payment-method p [type="radio"]:not(:checked)+label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.order-details .payment-method p [type="radio"]:checked+label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.order-details .btn.order-btn {
  display: block;
  margin-top: 25px;
}

.page-title-area {
  position: relative;
  z-index: 1;
  background: unset;
  padding-top: 190px;
  padding-bottom: 100px;
  text-align: center;
  border-bottom: 1px dashed #f1f1f1;
}

.page-title-area h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

.faq-accordion {
  padding: 60px;
  background: #ffffff;
  border-radius: 10px;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 20px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
  display: block;
  padding: 12px 20px 12px 55px;
  text-decoration: none;
  color: #0e314c;
  position: relative;
  background: #fff4e9;
  height: 75px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: justify;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  text-align: center;
  height: 100%;
  background: #f7993b;
  color: #ffffff;
}

.faq-accordion .accordion .accordion-item .accordion-title i::before {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
}

.faq-accordion .accordion .accordion-item .accordion-title.active {
  border-bottom-color: #e1e1e1;
}

.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
  content: "\f10f";
}

.faq-accordion .accordion .accordion-item .accordion-content {
  display: none;
  position: relative;
  overflow: hidden;
  padding: 15px 0 0;
  line-height: 1.9;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}

.faq-contact {
  margin-top: 45px;
  -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
  padding: 60px;
  border-radius: 4px;
  background: #ffffff;
}

.faq-contact h3 {
  font-size: 24px;
  margin-bottom: 25px;
}

.faq-contact form .btn {
  margin-top: 10px;
}

.pagination-area {
  text-align: center;
  margin-top: 20px;
}

.pagination-area ul {
  padding: 0;
  margin: 0;
}

.pagination-area ul .page-item .page-link {
  padding: 11px 20px;
  margin: 0 -1px;
  color: #6f8ba4;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
  box-shadow: 0 2px 10px 0 #d8dde6;
}

.pagination-area ul .page-item .page-link:hover,
.pagination-area ul .page-item .page-link:focus {
  color: #ffffff;
  background-color: #2882c4;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.pagination-area ul .page-item .page-link svg {
  width: 16px;
}

.pagination-area ul .page-item.active .page-link {
  color: #ffffff;
  background-color: #2882c4;
}

.blog-details-area {
  position: relative;
}

.blog-details-area .blog-details .article-img {
  position: relative;
}

.blog-details-area .blog-details .article-img img {
  width: 100%;
}

.blog-details-area .blog-details .article-img .date {
  position: absolute;
  bottom: 15px;
  width: 100px;
  right: 15px;
  height: 100px;
  text-align: center;
  font-size: 20px;
  background: #2882c4;
  color: #ffffff;
  border-radius: 5px;
  padding-top: 18px;
  line-height: 30px;
}

.blog-details-area .blog-details .article-content {
  padding-bottom: 15px;
}

.blog-fulltxt ul li,
.blog-fulltxt ol li {
  color: black;
  line-height: 1.8;
}

.blog-details-area .blog-details .article-content ul.category {
  margin-bottom: 25px;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.blog-details-area .blog-details .article-content ul.category li {
  display: inline-block;
  margin-right: 5px;
}

.blog-details-area .blog-details .article-content ul.category li a {
  background: #2882c4;
  color: #ffffff;
  padding: 5px 15px 3px;
  display: inline-block;
  border-radius: 3px;
}

.blog-details-area .blog-details .article-content h3 {
  font-size: 20px;
  font-weight: bold;
}

.blog-details-area .blog-details .article-content p {
  margin-bottom: 0;
}

.blog-details-area .blog-details .article-content .blockquote {
  margin-bottom: 20px;
  margin-top: 20px;
  background: #f7fafd;
  padding: 25px;
  border-left: 4px solid #2882c4;
}

.blog-details-area .blog-details .article-content .blockquote p {
  margin-top: 0;
  font-size: 17px;
}

.blog-details-area .blog-details .article-content .share-post {
  text-align: center;
  margin-top: 30px;
}

.blog-details-area .blog-details .article-content .share-post ul li a {
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 38px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  color: #0e314c;
  margin: 3px;
}

.blog-details-area .blog-details .article-content .share-post ul li a:hover,
.blog-details-area .blog-details .article-content .share-post ul li a:focus {
  color: #ffffff;
  border-color: #2882c4;
  background-color: #2882c4;
}

.blog-details-area .blog-details .article-content .share-post ul li a svg {
  width: 16px;
}

.blog-details-area .post-controls-buttons {
  overflow: hidden;
  background: #ffffff;
  padding-top: 15px;

}

.blog-details-area .post-controls-buttons .controls-left {
  float: left;
}

.blog-details-area .post-controls-buttons .controls-right {
  float: right;
}

.blog-details-area .post-controls-buttons div a {
  border: 1px solid #eeeeee;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 5px;
}

.blog-details-area .post-controls-buttons div a:hover,
.blog-details-area .post-controls-buttons div a:focus {
  color: #ffffff;
  background: #2882c4;
  border-color: #2882c4;
}

.blog-details-area .post-comments {
  padding-top: 30px;
  background: #ffffff;
}

.blog-details-area .post-comments h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.blog-details-area .post-comments .single-comment {
  position: relative;
  padding-left: 95px;
  margin-top: 30px;
}

.blog-details-area .post-comments .single-comment .comment-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 75px;
  height: 75px;
}

.blog-details-area .post-comments .single-comment .comment-img img {
  border-radius: 50%;
}

.blog-details-area .post-comments .single-comment .comment-content h4 {
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.blog-details-area .post-comments .single-comment .comment-content span {
  color: #6084a4;
  font-size: 13px;
}

.blog-details-area .post-comments .single-comment .comment-content p {
  margin-bottom: 10px;
  font-size: 13.3px;
}

.blog-details-area .post-comments .single-comment .comment-content a {
  position: absolute;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
}

.blog-details-area .post-comments .single-comment.left-m {
  margin-left: 85px;
}

.blog-details-area .leave-a-reply {
  padding: 30px;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035),
    0 7px 18px rgba(0, 9, 128, 0.05);
  margin-top: 25px;
}

.blog-details-area .leave-a-reply h3 {
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .pagination-area ul .page-item .page-link {
    padding: 11px 15px;
  }

  .m-liveprev-btn {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 30px;
  }
}

.project-details-image {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.project-details-image img {
  width: 100%;
  border: 1px solid whitesmoke;
  border-radius: 5px;
}

.project-details-desc h3 {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
}

.project-details-desc .project-details-information {
  overflow: hidden;
}

.project-details-desc .project-details-information .single-info-box {
  float: left;
  width: 20%;
  margin-top: 30px;
}

.project-details-desc .project-details-information .single-info-box h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.project-details-desc .project-details-information .single-info-box p {
  margin-bottom: 0;
}

.project-details-desc .project-details-information .single-info-box ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.project-details-desc .project-details-information .single-info-box ul li {
  display: inline-block;
}

.project-details-desc .project-details-information .single-info-box ul li a {
  margin-right: 4px;
  color: #6084a4;
}

.project-details-desc .project-details-information .single-info-box ul li a svg {
  width: 18px;
}

.project-details-desc .project-details-information .single-info-box ul li a:hover {
  color: #2882c4;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.project-details-desc .project-details-information .single-info-box .btn {
  box-shadow: none;
}

.contact-info-box {
  text-align: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: #ffffff;
  padding: 30px;
}

.contact-info-box .icon {
  display: inline-block;
  width: 65px;
  height: 65px;
  line-height: 62px;
  background: #f7fafd;
  border-radius: 50%;
  font-size: 30px;
  color: #2882c4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.contact-info-box h3 {
  font-size: 18px;
  margin: 25px 0 15px;
}

.contact-info-box p {
  margin-bottom: 0;
}

.contact-info-box p a {
  color: #6084a4;
}

.contact-info-box p a:hover {
  color: #2882c4;
}

.contact-info-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.contact-info-box:hover .icon {
  color: #ffffff;
  background: #2882c4;
}

#map {
  height: 500px;
  width: 100%;
}

#contactForm .help-block ul {
  margin: 0;
}

#contactForm .help-block ul li {
  color: red;
}

#contactForm .btn {
  margin-top: 10px;
}

#contactForm #msgSubmit.text-danger,
#contactForm #msgSubmit.text-success {
  margin: 20px 0 0;
}

.coming-soon-area {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-image: url(https://source.unsplash.com/random/1920x1080?coding?programming?office);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-area::before {
  z-index: -1;
  background: linear-gradient(-9deg, #2882c4 0%, #064777 40%, #3fc6f1 100%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.8;
}

.coming-soon-area .social-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  bottom: 30px;
}

.coming-soon-area .social-list li {
  display: inline-block;
}

.coming-soon-area .social-list li.list-heading {
  display: block;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.coming-soon-area .social-list li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #2882c4;
  color: #ffffff;
  border-radius: 50%;
  margin: 0 2px;
}

.coming-soon-area .social-list li a svg {
  width: 15px;
}

.coming-soon-area .social-list li a.email-icon {
  background-color: #3fc6f1;
}

.coming-soon-area .social-list li a.facebook {
  background-color: #3b5998;
}

.coming-soon-area .social-list li a.twitter {
  background-color: #1da1f2;
}

.coming-soon-area .social-list li a.linkedin {
  background-color: #0077b5;
}

.coming-soon-area .social-list li a.instagram {
  background-color: #c13584;
}

.coming-soon-area .social-list li a:hover,
.coming-soon-area .social-list li a:focus {
  background-color: #212121;
}

.coming-soon-content {
  text-align: center;
  max-width: 820px;
  margin: -80px auto 0;
}

.crpa-logodiv {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.crpa-logodiv img {
  width: 100%;
}

.coming-soon-content h1 {
  color: #ffffff;
  font-size: 55px;
  font-weight: 600;
  margin-bottom: 15px;
}

.coming-soon-content p {
  color: #ffffff;
  font-size: 16px;
  margin: 0 auto;
  max-width: 620px;
}

.coming-soon-content form {
  position: relative;
  margin: 35px auto 55px;
  max-width: 520px;
}

.coming-soon-content form .email-input {
  display: block;
  width: 100%;
  height: 56px;
  border: none;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 5px rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  padding: 15px 25px;
  font-size: 14px;
  outline: 0 !important;
  background: #f1f2f3;
}

.coming-soon-content form .submit-btn {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 50px;
  background: #2882c4;
  color: #ffffff;
  border: none;
  border-radius: 40px;
  width: 130px;
  outline: 0 !important;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  font-weight: 500;
}

.coming-soon-content form .submit-btn:hover,
.coming-soon-content form .submit-btn:focus {
  background-color: #3fc6f1;
}

.coming-soon-content #timer {
  margin-top: 25px;
}

.coming-soon-content #timer div {
  background: #ffffff;
  display: inline-block;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  color: #2882c4;
  -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
  margin: 0 10px;
  padding-top: 18px;
  font-size: 35px;
  font-weight: 500;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -4px;
  color: #6084a4;
  font-size: 15px;
}

.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
}

.error-content .notfound-404 {
  position: relative;
  height: 280px;
  z-index: -1;
  margin-bottom: 30px;
}

.error-content .notfound-404 h1 {
  font-size: 230px;
  font-weight: 900;
  margin: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url(../../assets/img/oops-bg.jpg) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}

.error-content h3 {
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 15px;
}

.error-content p {
  max-width: 550px;
  margin: 0 auto 25px;
}

.cta-area {
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
}

.cta-area .container {
  max-width: 945px;
}

.cta-content h3 {
  color: #ffffff;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.cta-right-content {
  text-align: right;
}

.cta-right-content .hosting-price {
  display: inline-block;
}

.cta-right-content .hosting-price span {
  color: #f6fe6a;
  display: block;
}

.cta-right-content .hosting-price h4 {
  margin: 10px 0 0;
  color: #ffffff;
  font-size: 20px;
}

.cta-right-content .buy-btn {
  display: inline-block;
  padding-left: 35px;
  position: relative;
  top: -15px;
}

.cta-right-content .buy-btn .btn-primary {
  background: #2e70a6;
  -webkit-box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
  box-shadow: 0 13px 27px 0 rgba(63, 198, 241, 0.25);
}

.cta-right-content .buy-btn .btn-primary::after,
.cta-right-content .buy-btn .btn-primary::before {
  background: #ffffff;
  -webkit-box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
  box-shadow: 0 13px 27px 0 rgba(40, 130, 196, 0.25);
}

.cta-right-content .buy-btn .btn-primary:hover,
.cta-right-content .buy-btn .btn-primary:focus {
  color: #2882c4;
}

.repair-main-banner {
  overflow-x: hidden;
  padding-top: 200px;
  padding-bottom: 140px;
}

.repair-banner-content h1 {
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 500;
}

.repair-banner-content p {
  margin-bottom: 30px;
}

.repair-banner-image {
  position: relative;
  text-align: center;
}

.repair-banner-image img:nth-child(2) {
  position: absolute;
  left: 0;
  top: -65px;
  right: -30px;
  margin: 0 auto;
  z-index: -1;
}

.repair-banner-image img:nth-child(3) {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -69px;
  z-index: -2;
}

.repair-banner-image img:nth-child(4) {
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
  z-index: -1;
  margin: 0 auto;
}

.repair-partner-area {
  padding-top: 45px;
  padding-bottom: 45px;
}

.single-repair-partner {
  text-align: center;
}

.single-repair-partner a {
  display: block;
  position: relative;
}

.single-repair-partner a img {
  width: auto !important;
  display: inline-block !important;
}

.single-repair-partner a img:last-child {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.single-repair-partner a:hover img:last-child,
.single-repair-partner a:focus img:last-child {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.partner-slides.owl-theme .owl-dots {
  margin-top: 0;
}

.repair-about-content .sub-title {
  text-transform: uppercase;
  color: #2882c4;
  display: block;
  font-size: 15px;
  font-weight: 400;
}

.repair-about-content h2 {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.repair-about-content ul {
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-right: -10px;
  margin-left: -10px;
}

.repair-about-content ul li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  margin-top: 25px;
  color: #6084a4;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
}

.repair-about-content ul li span {
  background-color: #ffffff;
  display: block;
  position: relative;
  z-index: 1;
  padding: 15px;
  color: #6084a4;
  border-radius: 3px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
}

.repair-about-content ul li span i-feather {
  width: auto;
  height: auto;
}

.repair-about-content ul li span svg {
  margin-right: 5px;
  display: inline-block;
  color: #2882c4;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 18px;
}

.repair-about-content ul li span::before {
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.repair-about-content ul li span:hover,
.repair-about-content ul li span:focus {
  color: #ffffff;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.repair-about-content ul li span:hover::before,
.repair-about-content ul li span:focus::before {
  opacity: 1;
  visibility: visible;
}

.repair-about-content ul li span:hover svg,
.repair-about-content ul li span:focus svg {
  color: #ffffff;
}

.repair-about-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.repair-about-image img {
  position: absolute;
}

.repair-about-image img:nth-child(1) {
  left: 0;
  top: 0;
  z-index: 1;
}

.repair-about-image img:nth-child(2) {
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  text-align: center;
  z-index: 2;
  margin: 0 auto;
  border: 5px solid #f8fbff;
  width: 350px;
}

.repair-about-image img:nth-child(3) {
  right: 0;
  bottom: 0;
  z-index: 1;
}

.repair-about-image img:nth-child(4) {
  right: 0;
  top: 0;
}

.repair-about-image img:nth-child(5) {
  left: 0;
  bottom: 0;
}

.repair-services-area {
  padding-bottom: 50px;
}

.single-repair-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-repair-services::before {
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: -1;
  border: 1px dashed #c8e6fd;
}

.single-repair-services::after {
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  border: 1px dashed #c8e6fd;
}

.single-repair-services h3 {
  font-size: 18px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 22px;
  margin-bottom: 17px;
}

.single-repair-services .icon {
  display: inline-block;
  color: #2882c4;
  width: 85px;
  height: 85px;
  line-height: 85px;
  border: 1px solid #e3f7e9;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-repair-services .icon i::before {
  font-size: 35px;
}

.single-repair-services p {
  margin-bottom: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-repair-services a {
  width: 35px;
  background-color: #f9fafb;
  height: 35px;
  line-height: 35px;
  color: #0e314c;
  border-radius: 50%;
}

.single-repair-services a:hover {
  background-color: #0e314c;
  color: #fff;
}

.single-repair-services a svg {
  width: 15px;
}

.single-repair-services:hover::before,
.single-repair-services:focus::before {
  opacity: 0;
  visibility: hidden;
}

.single-repair-services:hover::after,
.single-repair-services:focus::after {
  opacity: 0.97;
  visibility: visible;
}

.single-repair-services:hover h3,
.single-repair-services:focus h3 {
  color: #ffffff;
}

.single-repair-services:hover p,
.single-repair-services:focus p {
  color: #ffffff;
}

.single-repair-services:hover .icon,
.single-repair-services:focus .icon {
  border-color: #ffffff;
  color: #ffffff;
}

.single-repair-services:hover a,
.single-repair-services:focus a {
  background-color: #ffffff;
  color: #2882c4;
}

.repair-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cta-repair-content h3 {
  color: #ffffff;
  font-size: 25px;
  margin-bottom: 17px;
  font-weight: 600;
}

.cta-repair-content p {
  color: #ffffff;
  opacity: 0.95;
  margin-bottom: 25px;
}

.cta-repair-img {
  text-align: right;
}

.circle-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.cta-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.repair-team-area .container {
  max-width: 1175px;
}

.repair-team-area .single-team .team-content {
  padding-left: 25px;
  padding-right: 25px;
}

.repair-why-choose-us {
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
}

.repair-why-choose-us .section-title h2 {
  color: #ffffff;
}

.repair-why-choose-us .section-title .bar::before {
  background: #ffffff;
}

.repair-why-choose-us .section-title p {
  color: #ffffff;
  opacity: 0.95;
}

.repair-why-choose-us::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 37%;
  width: 100%;
  background: #ffffff;
  z-index: -1;
}

.single-repair-box {
  text-align: center;
  background-color: #ffffff;
  border: 1px dashed #c8e6fd;
  padding: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-repair-box h3 {
  font-size: 18px;
  margin-top: 22px;
  margin-bottom: 17px;
}

.single-repair-box p {
  margin-bottom: 25px;
}

.single-repair-box a {
  width: 40px;
  background-color: #eef0f3;
  height: 40px;
  line-height: 37px;
  color: #0e314c;
  border-radius: 50%;
}

.single-repair-box a svg {
  width: 18px;
}

.single-repair-box .icon {
  display: inline-block;
  border: 1px solid #43e794;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  position: relative;
  color: #ffffff;
  z-index: 1;
}

.single-repair-box .icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  border-radius: 50%;
  margin: 6px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-repair-box .icon i::before {
  font-size: 35px;
}

.single-repair-box .back-icon {
  position: absolute;
  top: 58%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #b9b9b9;
  opacity: 0.1;
  z-index: -1;
}

.single-repair-box .back-icon i::before {
  font-size: 200px;
}

.single-repair-box:hover,
.single-repair-box:focus {
  border-color: #2882c4;
}

.single-repair-box:hover a,
.single-repair-box:focus a {
  background-color: #43e794;
  color: #ffffff;
}

.single-repair-feedback {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 55px;
  position: relative;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.single-repair-feedback .client-img {
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  display: inline-block;
  padding-left: 108px;
  padding-top: 18px;
}

.single-repair-feedback .client-img img {
  border-radius: 50%;
  border: 2px solid #2882c4;
  padding: 3px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
}

.single-repair-feedback .client-img h3 {
  font-size: 18px;
  margin: 0;
}

.single-repair-feedback .client-img span {
  display: block;
  color: #3fc6f1;
  margin-top: 8px;
}

.single-repair-feedback p {
  font-size: 15px;
}

.single-repair-feedback::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  right: 0;
  margin: 0 auto;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.single-repair-feedback::after {
  content: "\f11b";
  color: #d9dcde;
  font-family: Flaticon;
  font-size: 30px;
  font-style: normal;
  font-size: 65px;
  position: absolute;
  top: 0;
  left: 25px;
}

.iot-main-banner {
  position: relative;
  z-index: 1;
  padding-top: 215px;
  padding-bottom: 150px;
}

.iot-banner-content {
  max-width: 445px;
}

.iot-banner-content span {
  color: #2882c4;
  font-size: 15px;
  display: block;
}

.iot-banner-content h2 {
  font-size: 40px;
  margin-top: 7px;
  margin-bottom: 17px;
  line-height: 1.3;
}

.iot-banner-content .btn {
  margin-top: 10px;
}

.iot-banner-image {
  position: absolute;
  text-align: center;
  right: 30px;
  top: 56%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.iot-banner-image img:first-child {
  max-width: 730px;
}

.iot-banner-image img:last-child {
  position: absolute;
  left: -118px;
  right: 0;
  margin: 0 auto;
  top: 39px;
  z-index: -1;
}

.animate-border span {
  position: absolute;
  display: block;
  width: 5%;
  padding-bottom: 5%;
  top: 51%;
  left: 67%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  pointer-events: none;
  border-radius: 50%;
  z-index: -2;
}

.animate-border span:nth-child(1) {
  -webkit-animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
  animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(2) {
  -webkit-animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
  animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(3) {
  -webkit-animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
  animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(4) {
  -webkit-animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
  animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
}

@-webkit-keyframes pulsei {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    border: 1.5px solid #b3eaf8;
    opacity: 1;
    width: 5%;
    padding-bottom: 5%;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
    width: 50%;
    border: 1.5px solid #b3eaf8;
    padding-bottom: 50%;
  }
}

@keyframes pulsei {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    border: 1.5px solid #b3eaf8;
    opacity: 1;
    width: 5%;
    padding-bottom: 5%;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
    width: 50%;
    border: 1.5px solid #b3eaf8;
    padding-bottom: 50%;
  }
}

.iot-partner-area {
  padding-top: 45px;
  padding-bottom: 45px;
}

.single-iot-partner {
  text-align: center;
}

.single-iot-partner a {
  display: block;
  position: relative;
}

.single-iot-partner a img {
  width: auto !important;
  display: inline-block !important;
}

.single-iot-partner a img:last-child {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.single-iot-partner a:hover img:last-child,
.single-iot-partner a:focus img:last-child {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.iot-services-area {
  padding-bottom: 50px;
}

.single-iot-services {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  padding: 40px 30px;
  text-align: center;
  background-color: #ffffff;
  border: 1px dashed #c8e6fd;
}

.single-iot-services::after {
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.single-iot-services h3 {
  font-size: 18px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 22px;
  margin-bottom: 17px;
  line-height: 1.5;
}

.single-iot-services .icon {
  display: inline-block;
  color: #2882c4;
  width: 85px;
  height: 85px;
  line-height: 85px;
  border: 1px solid #e3f7e9;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-iot-services .icon i::before {
  font-size: 35px;
}

.single-iot-services p {
  margin-bottom: 25px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-iot-services a {
  width: 35px;
  background-color: #f9fafb;
  height: 35px;
  line-height: 35px;
  color: #0e314c;
  border-radius: 50%;
}

.single-iot-services a svg {
  width: 15px;
}

.single-iot-services:hover::after,
.single-iot-services:focus::after {
  opacity: 1;
  visibility: visible;
}

.single-iot-services:hover h3,
.single-iot-services:focus h3 {
  color: #ffffff;
}

.single-iot-services:hover p,
.single-iot-services:focus p {
  color: #ffffff;
}

.single-iot-services:hover .icon,
.single-iot-services:focus .icon {
  border-color: #ffffff;
  color: #ffffff;
}

.single-iot-services:hover a,
.single-iot-services:focus a {
  background-color: #ffffff;
  color: #2882c4;
}

.iot-cta-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cta-iot-content h3 {
  color: #ffffff;
  font-size: 25px;
  margin-bottom: 17px;
}

.cta-iot-content p {
  color: #ffffff;
  opacity: 0.95;
  margin-bottom: 25px;
}

.cta-iot-img {
  text-align: center;
}

.iot-features-content h3 {
  font-size: 26px;
  margin-bottom: 20px;
}

.iot-features-content .btn {
  margin-top: 15px;
}

.iot-features-image {
  position: relative;
  text-align: center;
}

.iot-team-area .container {
  max-width: 1175px;
}

.iot-team-area .single-team .team-content {
  padding-left: 25px;
  padding-right: 25px;
}

.iot-why-choose-us {
  position: relative;
  z-index: 1;
  background: linear-gradient(135deg, #2882c4 0%, #3fc6f1 100%);
}

.iot-why-choose-us .section-title h2 {
  color: #ffffff;
}

.iot-why-choose-us .section-title .bar::before {
  background: #ffffff;
}

.iot-why-choose-us .section-title p {
  color: #ffffff;
  opacity: 0.95;
}

.iot-why-choose-us::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 37%;
  width: 100%;
  background: #ffffff;
  z-index: -1;
}

.single-iot-box {
  text-align: center;
  background-color: #ffffff;
  border: 1px dashed #c8e6fd;
  padding: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-iot-box h3 {
  font-size: 18px;
  margin-top: 22px;
  margin-bottom: 17px;
}

.single-iot-box p {
  margin-bottom: 25px;
}

.single-iot-box a {
  width: 40px;
  background-color: #eef0f3;
  height: 40px;
  line-height: 40px;
  color: #0e314c;
  border-radius: 50%;
}

.single-iot-box a svg {
  width: 18px;
  margin-top: -3px;
}

.single-iot-box .icon {
  display: inline-block;
  border: 1px solid #f3f8fd;
  width: 100px;
  height: 100px;
  line-height: 96px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.single-iot-box .icon::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  background: #f7fafd;
  border-radius: 50%;
  margin: 6px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.single-iot-box .icon i::before {
  font-size: 35px;
}

.single-iot-box .icon img {
  width: 45px;
}

.single-iot-box:hover,
.single-iot-box:focus {
  border-color: #2882c4;
}

.single-iot-box:hover a,
.single-iot-box:focus a {
  background-color: #2882c4;
  color: #ffffff;
}

.footer-area {
  padding-top: 80px;
  position: relative;
  z-index: 1;
}

.footer-area .map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: -1;
  bottom: 0;
  opacity: 0.7;
}

.single-footer-widget .logo {
  margin-bottom: 20px;
}

.single-footer-widget .logo a {
  display: block;
}

.single-footer-widget h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  border-bottom: 1px solid #d8ebfd;
  padding-bottom: 10px;
}

.single-footer-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget ul.list li {
  margin-bottom: 10px;
  line-height: 1.8;
}

.single-footer-widget ul.list li a {
  color: #6084a4;
}

.single-footer-widget ul.list li a:hover {
  color: #2882c4;
  padding-left: 7px;
}

.single-footer-widget ul.list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget ul.footer-contact-info {
  margin-bottom: 20px;
}

.single-footer-widget ul.footer-contact-info li {
  position: relative;
  color: #6084a4;
  margin-bottom: 8px;
  padding-left: 25px;
  line-height: 1.8;
}

.single-footer-widget ul.footer-contact-info li svg {
  position: absolute;
  left: 0;
  width: 15px;
  top: 5px;
  color: #2882c4;
}

.single-footer-widget ul.footer-contact-info li i-feather {
  width: auto !important;
  height: auto !important;
}

.single-footer-widget ul.footer-contact-info li:last-child {
  margin-bottom: 0;
}

.single-footer-widget ul.footer-contact-info li a {
  color: #6084a4;
}

.single-footer-widget ul.footer-contact-info li a:hover {
  color: #2882c4;
}

.single-footer-widget ul.social-links li {
  display: inline-block;
  margin-right: 8px;
}

.single-footer-widget ul.social-links li:last-child {
  margin-right: 0;
}

.single-footer-widget ul.social-links li a {
  width: 32px;
  text-align: center;
  line-height: 29px;
  height: 32px;
  border: 1px solid #2882c4;
  border-radius: 50%;
  color: #2882c4;
}

.single-footer-widget ul.social-links li a svg {
  width: 15px;
}

.single-footer-widget ul.social-links li a.facebook {
  border-color: #4267b2;
  color: #4267b2;
}

.single-footer-widget ul.social-links li a.facebook:hover {
  background: #4267b2;
  color: #ffffff;
}

.single-footer-widget ul.social-links li a.twitter {
  border-color: #38a1f3;
  color: #38a1f3;
}

.single-footer-widget ul.social-links li a.twitter:hover {
  background: #38a1f3;
  color: #ffffff;
}

.single-footer-widget ul.social-links li a.linkedin {
  border-color: #0077b5;
  color: #0077b5;
}

.single-footer-widget ul.social-links li a.linkedin:hover {
  background: #0077b5;
  color: #ffffff;
}

.single-footer-widget ul.social-links li a.instagram {
  border-color: #231f20;
  color: #231f20;
}

.single-footer-widget ul.social-links li a.instagram:hover {
  background: #231f20;
  color: #ffffff;
}

.single-footer-widget ul.social-links li a:hover {
  background: #2882c4;
  color: #ffffff;
}

.copyright-area {
  margin-top: 80px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #d8ebfd;
  text-align: center;
}

.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 85px;
  right: 0;
  color: #ffffff;
  background-color: #3fc6f1;
  z-index: 1;
  display: none;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 46px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.go-top:hover {
  color: #ffffff;
  background: #2882c4;
}

.owl-carousel {
  display: block !important;
}

@media only screen and (max-width: 767px) {
  .ind-services-area {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .globe-img {
    width: 500px !important;
    height: 430px !important;
    margin-left: -30px !important;
  }

  .globe-image {
    width: 450px !important;
    height: 450px !important;
  }

  .globe-info {
    width: 250px !important;
    height: 250px !important;
    background-size: contain !important;
    left: 20% !important;
    top: 20% !important;
  }

  .globe-info p {
    font-size: 14px !important;
    margin-top: -15px !important;
    margin-bottom: 15px !important;
  }

  .globe-info h2 {
    font-size: 25px !important;
    margin-bottom: 25px;
  }

  .globe-info h2 sup {
    font-size: 18px !important;
  }

  .globe-info p span {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 768px) {
  .services-content .box .fa {
    left: 5px;
  }
}

.globe-img {
  width: 700px;
  height: 700px;
  margin: 0 auto;
  position: relative;
}

.globe-info,
.globe-info-img {
  background: url(../../assets/img/globe-info-blue.png) center center no-repeat;
  position: absolute;
  top: 22%;
  left: 22%;
  z-index: 111;
  width: 345px;
  height: 345px;
  padding: 70px 50px;
  text-align: center;
}

.globe-info p {
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.globe-info h2 {
  font-weight: 400;
  font-size: 100px;
  color: #fff;
}

.globe-info h2 sup {
  font-size: 60px;
}

.globe-info p span {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 21px;
  letter-spacing: 6px;
}

.globe-info-img,
.globe-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 650px;
  height: 650px;
  background-size: 100%;
  margin: 0;
  -webkit-animation: spin 30s linear infinite;
  -moz-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 767px) {
  .tech-part-sec .typing {
    font-size: 20px !important;
  }

  .tech-part-sec .typing-div {
    height: 70px;
    margin-bottom: -15px;
  }

  .tech-part-sec:before {
    content: none !important;
  }
}

.tech-part-sec {
  position: relative;
}

@keyframes rotateMe {
  from {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
  }
}

@-o-keyframes rotateMe {
  from {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
  }
}

@-moz-keyframes rotateMe {
  from {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
  }
}

@-webkit-keyframes rotateMe {
  from {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  to {
    transform-origin: center;
    -moz-transform-origin: center;
    -o-transform-origin: center;
    -ms-transform-origin: center;
    transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
  }
}

.tech-part-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tech-part-sec h2 {
  text-align: center;
  font-size: 40px;
}

.tech-part-sec h2 span {
  color: #f7993b;
}

.tech-part-sec .typing-div {
  text-align: center;
  margin-top: 25px;
  display: block;
}

.tech-part-sec .typing {
  text-align: center;
  font-size: 25px;
  color: #0e314c;
  display: inline;
}

.tech-part-p2 {
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px !important;
}

.tp-img-div {
  width: 100%;
  max-width: 151px;
  height: 166;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.tp-img-div img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .tp-img-div {
    max-width: 110px !important;
  }
}

@media only screen and (min-width: 992px) {
  .team-testmon-sec .owl-carousel {
    margin-bottom: -53px !important;
  }

  .team-testmon-sec .owl-dots {
    margin-top: -40px !important;
    margin-bottom: 50px !important;
  }
}

.team-testmon-sec .owl-dots {
  position: inherit !important;
}


.formcon-row.deactive {
  display: none !important;
}

.foo-top-sec.deactive {
  display: none !important;
}

.footer-sec.deactive {
  padding-top: 0 !important;
}

@media only screen and (min-width: 992px) {
  .servpage-header {
    padding-top: 190px !important;
  }
}

@media only screen and (max-width: 991px) {
  .single-box .icon {
    width: 45px;
    height: 45px;
    line-height: 30px;
  }

  .servpage-header {
    padding-top: 130px !important;
  }

  .main-banner {
    background: none;
  }
}

@media only screen and (max-width: 767px) {
  .servpage-header {
    padding-top: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .dm-contetbox {
    padding-top: 0px !important;
  }

  .dm-contetbox .webdev-div1-h1 {
    font-size: 25px !important;
  }
}

.webdev-sec2 {
  padding-top: 50px;
}

.webdev-div1-h1 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 32px;
  text-transform: capitalize;
  line-height: 1.4;
  margin-top: 15px;
}

.webdev-div1-h1 span {
  font-weight: bold;
}

.webdev-div1-p1 {
  font-size: 18px;
  margin-top: 15px;
}

.webdev-imgdiv1 img {
  width: 100%;
}

.servtabs-sec .mvc-page-links {
  padding: 30px 0 30px 0;
  overflow: hidden;
  clear: both;
  min-height: 80px;
}

.servtabs-sec .mvc-fixd {
  height: 80px;
  float: left;
  width: 100%;
  position: relative;
}

.servtabs-sec .mvc-fixd .mvc-page-links {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.servtabs-sec .mvc-page-links.fixed {
  position: fixed !important;
  top: 118px !important;
  width: 100%;
  background-color: #fff;
  z-index: 998;
  border-bottom: 1px solid whitesmoke;
  border-top: 1px solid whitesmoke;
}

.servtabs-sec .mvc-page-links .discuss-btn a span .icons.arrow-right-white:after {
  -moz-animation: mymove 1.5s infinite;
  -webkit-animation: mymove 1.5s infinite;
  animation: mymove 1.5s infinite;
}

.servtabs-sec .mvc-page-links .wrapper {
  position: relative;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li {
  display: inline-block;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a {
  position: relative;
  padding: 14px 25px 14px 25px;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a .circle {
  width: 12px;
  height: 12px;
  background: #2e70a6;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  top: -3px;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a span {
  color: #2e70a6;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s ease-in;
  -moz-transition: max-width 0.5s ease-in;
  -webkit-transition: max-width 0.5s ease-in;
  display: inline-block;
  cursor: pointer;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a:hover span {
  transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  max-width: 250px;
  color: #2e70a6;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a:hover .circle {
  background: #2e70a6;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a.active span {
  transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  max-width: 250px;
  color: #2882c4;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -17px;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li a.active .circle {
  background: #2882c4;
}

.servtabs-sec .mvc-page-links .wrapper .mvc-menu-list ul li .discuss-btn {
  position: absolute;
  right: 0;
  top: 0px;
}

.servtab-ove-link.active span {
  transition-delay: 0.2s !important;
  -moz-transition-delay: 0.2s !important;
  -webkit-transition-delay: 0.2s !important;
  max-width: 250px !important;
  color: #2882c4 !important;
}

.servtab-ove-link.active .circle {
  background: #2882c4 !important;
}

.port-techdiv {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.portico-div {
  width: 100%;
  max-width: 40px;
  margin-right: 15px;
}

.port-techdiv img {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .port-techdiv {
    margin-left: 15px !important;
  }
}

@media only screen and (min-width: 992px) {
  .serv-ourwork-sec .desktop-slider-none {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .serv-ourwork-sec .portfolio-item {
    max-width: 750px !important;
    margin: 0 auto !important;
  }

  .serv-ourwork-sec .owl-item.active.center {
    box-shadow: none !important;
  }

  .serv-ourwork-sec .desktop-slider-none {
    display: none !important;
  }

  .serv-ourwork-sec .owl-stage {
    padding-right: 0 !important;
  }

  .serv-ourwork-sec .owl-carousel {
    margin-left: 0 !important;
  }

  .serv-ourwork-sec .nav-left {
    display: none !important;
  }

  .serv-ourwork-sec .right-nav {
    margin: 0 auto !important;
    margin-top: 15px !important;
    background: #2882c4;
    padding: 10px 15px;
    border-radius: 3px;
    color: white;
  }

  .serv-ourwork-sec .right-nav span {
    color: white !important;
    font-weight: normal !important;
  }

  .serv-ourwork-sec .right-nav .arrow-right {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .serv-ourwork-sec {
    padding-top: 0 !important;
  }

  .serv-ourwork-head {
    font-size: 20px !important;
  }

  .serv-ourwork-sec .portfolio-block .card_wrapper {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .serv-ourwork-sec .portfolio-item {
    max-width: 100% !important;
  }

  .serv-ourwork-sec .portfolio-block {
    min-width: 100% !important;
    padding-top: 35px !important;
  }

  .serv-ourwork-sec .display-mobile-none {
    display: none !important;
  }

  .serv-ourwork-sec .display-desktop-none {
    display: block !important;
  }

  .serv-ourwork-sec .card_wrapper .content_image {
    padding: 0 20px !important;
  }

  .serv-ourwork-sec .portfolio-block .content {
    min-height: 199px !important;
    margin-top: 25px !important;
    padding-bottom: 15px;
  }

  .serv-ourwork-sec .owl-item {
    opacity: 1 !important;
    margin-left: 20px !important;
  }

  .serv-ourwork-sec .portfolio-block .pl-40 {
    padding-left: 20px !important;
  }

  .serv-ourwork-sec .portfolio-block .sub-heading {
    font-size: 14px !important;
    margin-bottom: 20px !important;
  }

  .portfolio-block .item_list {
    margin-left: 20px !important;
    padding-left: 20px !important;
  }

  .serv-ourwork-sec .nav-left {
    display: none !important;
  }

  .serv-ourwork-sec .right-nav {
    margin: 0 auto !important;
    margin-top: 15px !important;
    background: #2882c4;
    padding: 10px 15px;
    border-radius: 3px;
    color: white;
  }

  .serv-ourwork-sec .right-nav span {
    color: white !important;
    font-weight: normal !important;
  }

  .serv-ourwork-sec .owl-dots {
    padding-left: 30px !important;
  }

  .serv-ourwork-sec .portfolio-block .item_list li {
    font-weight: 300 !important;
    margin-bottom: 10px !important;
  }

  .serv-ourwork-sec .portfolio-block .sub-heading {
    line-height: 22px !important;
  }

  .serv-ourwork-sec .owl-stage {
    padding-right: 0 !important;
  }
}

.serv-ourwork-head {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

.serv-ourwork-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.serv-ourwork-sec .owl-carousel .owl-stage,
.serv-ourwork-sec .owl-carousel.owl-drag .owl-item {
  -ms-touch-action: auto;
  touch-action: auto;
}

.serv-ourwork-sec .portfolio-item {
  max-width: 730px;
}

.serv-ourwork-sec .portfolio-block {
  padding-top: 60px;
  border-radius: 6px;
  min-width: 750px;
  overflow: hidden;
}

.serv-ourwork-sec .portfolio-block .card_wrapper {
  display: flex;
}

.serv-ourwork-sec .card_wrapper .content {
  flex-basis: 50%;
}

.serv-ourwork-sec .card_wrapper .content_image {
  padding-left: 14px;
}

.serv-ourwork-sec .portfolio-block .heading {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 25px;
  color: #fff;
}

.serv-ourwork-sec .portfolio-block .sub-heading {
  font-size: 20px;
  margin-bottom: 33px;
  color: #fff;
  font-weight: 300;
}

.serv-ourwork-sec .portfolio-block .item_list {
  list-style-type: none;
  margin-left: 40px;
  padding-left: 20px;
  position: relative;
}

.serv-ourwork-sec .pl-40 {
  padding-left: 40px;
}

.serv-ourwork-sec .view_more {
  margin-top: 40px;
  margin-bottom: 40px;
}

.serv-ourwork-sec .view_more span {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.serv-ourwork-sec .view_more span:hover {
  margin-left: 10px;
}

.serv-ourwork-sec .portfolio-block .item_list li {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 10px;
}

.serv-ourwork-sec .portfolio-block .item_list li:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 11px;
  color: #fff;
  position: absolute;
  left: -3px;
  line-height: 24px;
}

.serv-ourwork-sec .display-inline {
  display: inline-block;
}

.serv-ourwork-sec .testimonial-rating>img {
  display: inline-block !important;
  margin-top: 50px !important;
}

.serv-ourwork-sec .rita_block {
  background: linear-gradient(#eae611, #076c1f);
  background: -webkit-linear-gradient(#eae611, #076c1f);
  background: -moz-linear-gradient(#eae611, #076c1f);
}

.serv-ourwork-sec .luxury_block {
  background: linear-gradient(#007b10, #c1312f);
  background: -webkit-linear-gradient(#007b10, #c1312f);
  background: -moz-linear-gradient(#007b10, #c1312f);
}

.serv-ourwork-sec .jeecee_block {
  background: linear-gradient(#283582, #878dad);
  background: -webkit-linear-gradient(#283582, #878dad);
  background: -moz-linear-gradient(#283582, #878dad);
}


.serv-ourwork-sec .callfive_block {
  background: linear-gradient(#e64644, #ff6600);
  background: -webkit-linear-gradient(#e64644, #ff6600);
  background: -moz-linear-gradient(#e64644, #ff6600);
}

.serv-ourwork-sec .discounter_block {
  background: linear-gradient(#ffc347, #f0a305);
  background: -webkit-linear-gradient(#ffc347, #f0a305);
  background: -moz-linear-gradient(#ffc347, #f0a305);
}

.serv-ourwork-sec .ualla_block {
  background: linear-gradient(#1c9cd9, #ec2646);
  background: -webkit-linear-gradient(#1c9cd9, #ec2646);
  background: -moz-linear-gradient(#1c9cd9, #ec2646);
}


.serv-ourwork-sec .dealshop_block {
  background: linear-gradient(#b51212, #2c2a5d);
  background: -webkit-linear-gradient(#b51212, #2c2a5d);
  background: -moz-linear-gradient(#b51212, #2c2a5d);
}

.serv-ourwork-sec .progarr_block {
  background: linear-gradient(#212121, #797979);
  background: -webkit-linear-gradient(#212121, #797979);
  background: -moz-linear-gradient(#212121, #797979);
}

.serv-ourwork-sec .sportsnet_block {
  background: linear-gradient(#0063aa, #00a161);
  background: -webkit-linear-gradient(#0063aa, #00a161);
  background: -moz-linear-gradient(#0063aa, #00a161);
}


.serv-ourwork-sec .dokument_block {
  background: linear-gradient(#ff0000, #ffc400);
  background: -webkit-linear-gradient(#ff0000, #ffc400);
  background: -moz-linear-gradient(#ff0000, #ffc400);
}

.serv-ourwork-sec .souq_block {
  background: linear-gradient(#feee03, #000003);
  background: -webkit-linear-gradient(#feee03, #000003);
  background: -moz-linear-gradient(#feee03, #000003);
}

.serv-ourwork-sec .zafif_block {
  background: linear-gradient(#f26524, #000000);
  background: -webkit-linear-gradient(#f26524, #000000);
  background: -moz-linear-gradient(#f26524, #000000);
}


.serv-ourwork-sec .cruisemag_block {
  background: linear-gradient(#5ce1e6, #c151b2);
  background: -webkit-linear-gradient(#5ce1e6, #c151b2);
  background: -moz-linear-gradient(#5ce1e6, #c151b2);
}

.serv-ourwork-sec .igsmunlock_block {
  background: linear-gradient(#313131, #57b6ff);
  background: -webkit-linear-gradient(#313131, #57b6ff);
  background: -moz-linear-gradient(#313131, #57b6ff);
}

.serv-ourwork-sec .espirito_block {
  background: linear-gradient(#1860a3, #6bb5fa);
  background: -webkit-linear-gradient(#1860a3, #6bb5fa);
  background: -moz-linear-gradient(#1860a3, #6bb5fa);
}

.serv-ourwork-sec .card-link {
  text-decoration: none;
}

.serv-ourwork-sec .carousel-navigation {
  display: flex;
  max-width: 44.79em;
  justify-content: space-between;
  margin: auto;
}

.serv-ourwork-sec .nav-left {
  display: flex;
}

.serv-ourwork-sec .item-number {
  margin-right: 30px;
  align-self: center;
}

.serv-ourwork-sec .item-number span {
  color: #999;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 21px;
}

.serv-ourwork-sec .right-nav {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.serv-ourwork-sec .right-nav:hover {
  margin-right: 5px;
}

.serv-ourwork-sec .right-nav span {
  color: #2e70a6;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
}

.serv-ourwork-sec .nav-buttons img {
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.serv-ourwork-sec .nav-buttons img:hover {
  box-shadow: 0 2px 6px 1px #eee;
}

.serv-ourwork-sec .nav-buttons img:active {
  box-shadow: 0 3px 7px 2px #eee;
}

.serv-ourwork-sec .previous-btn {
  margin-right: 5px;
}

.serv-ourwork-sec .right-nav .arrow-right {
  position: relative;
  top: -1.5px;
  margin-left: 10px;
}

.serv-ourwork-sec .owl-item {
  margin-bottom: 20px;
  width: auto;
}

.serv-ourwork-sec .owl-item {
  opacity: 0.2;
}

.serv-ourwork-sec .owl-item.active.center {
  box-shadow: 0 4px 19px -11px #000;
  opacity: 1;
}

.serv-ourwork-sec .owl-stage {
  display: flex;
}


@media only screen and (max-width: 767px) {
  .serv-ourwork-sec .owl-item.active.center {
    box-shadow: none !important;
  }

  .servove-text h2,
  .servove-text h3 {
    font-size: 20px !important;
    text-align: left !important;
  }

  .servove-text p {
    text-align: left !important;
  }

  .servove-quote p {
    font-size: 20px !important;
  }
}

.servove-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.servove-text h2,
.servove-text h3 {
  text-align: center;
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
  margin-bottom: 25px;
}

.servove-text p {
  text-align: center;
}

.servove-quote {
  position: relative;
  border: 4px solid rgba(139, 154, 178, 0.42);
  width: auto;
  display: table;
  margin: 0 auto;
  margin-top: 35px;
  margin-bottom: 25px;
  border-radius: 3px;
}

.servove-quote img {
  position: absolute;
  left: 30px;
  top: -23px;
  width: 52px;
  height: 37px;
}

.servove-quote p {
  font-size: 35px;
  line-height: normal;
  margin: 0;
  padding: 20px 30px;
  font-weight: 600;
  font-family: "Satisfy", cursive;
  color: #2882c4;
}

@media only screen and (max-width: 767px) {
  .servserv-box h3 {
    font-size: 18px !important;
  }

  .servserv-head {
    font-size: 20px !important;
  }
}

.servserv-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.servserv-head {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

.servserv-box {
  display: flex;
  margin-bottom: 40px;
}

.ssimg-box {
  width: 100%;
  max-width: 80px;
  margin-right: 15px;
}

.ssimg-box img {
  width: 100%;
}

.servserv-box h3 {
  font-size: 20px;
}

.servserv-box p {
  font-size: 14px;
}


@media only screen and (max-width: 767px) {
  .servtab-hwd-link {
    display: none;
  }

  .hwdi-head {
    font-size: 20px !important;
  }
}

.hwdi-head {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

.hwdi-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}

.hwd-box {
  position: relative;
  margin-bottom: 50px;
}

.hwdimg-box {
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
}

.hwdimg-box img {
  width: 100%;
}

.hwdtxt-box h4 {
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

.hwdtxt-box p {
  text-align: center;
}

.hwdline {
  position: absolute;
  top: 73px;
  right: -65px;
  z-index: -1;
}

.hwd-box5 .hwdline {
  left: -116px;
  top: 95px;
}

.hwd-box6 .hwdline {
  left: -120px;
  top: 80px;
}

.hwd-arrow {
  width: 12px;
  height: 12px;
  border-left: 4px solid #3fc6f1;
  border-top: 4px solid #3fc6f1;
  position: absolute;
}

.hwd-box1 .hwd-arrow {
  top: 11px;
  left: -12px;
  -webkit-transform: rotate(118deg);
  -ms-transform: rotate(118deg);
  transform: rotate(118deg);
}

.hwd-box2 .hwd-arrow {
  top: 1px;
  left: -8px;
  -webkit-transform: rotate(148deg);
  -ms-transform: rotate(148deg);
  transform: rotate(148deg);
}

.hwd-box3 .hwd-arrow {
  top: 35px;
  left: -11px;
  -webkit-transform: rotate(112deg);
  -ms-transform: rotate(112deg);
  transform: rotate(112deg);
}

.hwd-box4 .hwd-arrow {
  top: -6px;
  left: -10px;
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}

.hwd-box5 .hwd-arrow {
  top: 41px;
  left: 146px;
  -webkit-transform: rotate(-27deg);
  -ms-transform: rotate(-27deg);
  transform: rotate(-27deg);
}

.hwd-box6 .hwd-arrow {
  top: -3px;
  -webkit-transform: rotate(-38deg);
  -ms-transform: rotate(-38deg);
  transform: rotate(-38deg);
}

.hwdcount {
  display: block;
  width: 100%;
  max-width: 20px;
  margin: 0 auto;
  height: 20px;
  background: #3fc6f1;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: white;
  margin-top: 15px;
}

.platform-h5 {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

@media only screen and (max-width: 767px) {
  .serv-sol-head {
    font-size: 20px !important;
  }

  .single-team.hometeamsection {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100% !important;
  }

  .hometeamsectionmain .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: block !important;
  }

  .hometeamsectionmain .owl-carousel .owl-nav .owl-prev {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-left-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .hometeamsectionmain .owl-carousel .owl-nav .owl-next {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-right-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .hometeamsectionmain .owl-carousel .owl-nav .owl-prev:hover {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-left-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .hometeamsectionmain .owl-carousel .owl-nav .owl-next:hover {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-right-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .rpatestmonial .owl-theme .owl-dots .owl-dot {
    display: none;
  }

  .rpatestmonial .owl-carousel .owl-nav .owl-prev {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-left-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .rpatestmonial .owl-carousel .owl-nav .owl-next {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-right-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .rpatestmonial .owl-carousel .owl-nav .owl-prev:hover {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-left-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .rpatestmonial .owl-carousel .owl-nav .owl-next:hover {
    background: url(https://www.rpatechnologies.in/assets/img/ic-ar-circle-right-new.svg);
    background-repeat: no-repeat;
    height: 41px;
    color: #ff000000;
  }

  .rpatestmonial .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: block;
  }
}

.serv-sol-pro {
  padding-bottom: 30px;
}

.serv-sol-head {
  margin-top: -8px;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.4;
}

.servsol-box {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 10px !important;
  position: relative !important;
  overflow: hidden !important;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  box-shadow: 4px 4px 12px 0 rgba(46, 53, 144, 0.2);
  height: auto;
  margin-bottom: 35px;
}

.servsol-imgbox {
  position: relative !important;
  overflow: hidden !important;
  display: block !important;
}

.servsol-imgbox img {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin: auto;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.servsol-txt {
  padding: 10px 20px;
  min-height: 210px;
}

.servsol-txt h5 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 0;
}

.servsol-txt p {
  font-size: 14px;
  min-height: 140px;
}

@media only screen and (max-width: 767px) {
  .servsol-txt {
    min-height: auto !important;
    padding: 20px 20px !important;
  }

  .single-blog-post .blog-image a img {
    height: 100% !important;
  }

  .servsol-imgbox {
    margin-top: 15px;
  }

  .servsol-txt p {
    min-height: auto !important;
  }
}


.form-tel-box .iti {
  width: 100% !important;
}

.iti__country-list {
  z-index: 3 !important;
  width: 100% !important;
  min-width: 310px !important;
}


.formLoader {
  z-index: 1;
  height: 50px;
  line-height: 50px;
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
  color: #2d6fa6;
  letter-spacing: 0.2em;
  text-align: center;
  width: 100%;
  max-width: 250px;
  left: 0;
  right: 0;
  top: 40%;
  bottom: 0;
  margin: 0 auto;
}

.formLoader::before,
.formLoader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: #f7993a;
  position: absolute;
  -webkit-animation: load 0.7s infinite alternate ease-in-out;
  animation: load 0.7s infinite alternate ease-in-out;
}

.formLoader::before {
  top: 0;
}

.formLoader::after {
  bottom: 0;
}

@-webkit-keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 40px;
  }

  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }

  50% {
    height: 8px;
    width: 40px;
  }

  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}

@media (max-width: 767px) {
  .grey-text {
    color: #020202 !important;
  }

  .pb-5,
  .py-5 {
    padding-bottom: 1.5rem !important;
  }

  .pt-5,
  .py-5 {
    padding-top: 1.5rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 0rem !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 0rem !important;
  }

  .single-box p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: -6px;
    font-size: 13px;
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .partner-inner-para {
    margin-top: 10px;
    font-size: 18px;
  }
}

.wrap-loader {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #000;
  position: fixed;
  z-index: 10000000000;
  width: 100%;
  overflow: hidden;
  margin-top: -10px;
}

.loader {
  position: relative;
  width: 20rem;
  height: 20rem;
}

.loader::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8.6956521739rem;
  height: 8.6956521739rem;
  background: #000;
  border-radius: 50%;
}

.loader .box {
  position: absolute;
  width: 10rem;
  height: 10rem;
  overflow: hidden;
}

.loader .box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.1;
}

.loader .box:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .box:nth-child(1)::before {
  top: 50%;
  left: 50%;
  background: #3fc6f1;
  -webkit-animation: lightMe1 4s ease-out infinite normal;
  animation: lightMe1 4s ease-out infinite normal;
}

.loader .box:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .box:nth-child(2)::before {
  top: 50%;
  right: 50%;
  background: #2882c4;
  -webkit-animation: lightMe2 4s ease-out infinite normal;
  animation: lightMe2 4s ease-out infinite normal;
}

.loader .box:nth-child(3) {
  bottom: 0;
  right: 0;
}

.loader .box:nth-child(3)::before {
  bottom: 50%;
  right: 50%;
  background: #fff;
  -webkit-animation: lightMe3 4s ease-out infinite normal;
  animation: lightMe3 4s ease-out infinite normal;
}

.loader .box:nth-child(4) {
  bottom: 0;
  left: 0;
}

.loader .box:nth-child(4)::before {
  bottom: 50%;
  left: 50%;
  background: #f8f9fb;
  -webkit-animation: lightMe4 4s linear infinite normal;
  animation: lightMe4 4s linear infinite normal;
}

.loader .wrap-text {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.loader .text {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  -webkit-animation: slider 8s ease-in infinite;
  animation: slider 8s ease-in infinite;
}

.loader .text span {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 2em;
  color: #fff;
}

.loader-text {
  color: #fff;
  transform: translateY(-2rem);
  letter-spacing: 6px;
  opacity: 0.2;
  -webkit-animation: blink 1s ease-out infinite alternate;
  animation: blink 1s ease-out infinite alternate;
}

@-webkit-keyframes lightMe1 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe1 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe2 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe2 {
  0% {
    opacity: 0.1;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe3 {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe3 {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes lightMe4 {
  0% {
    opacity: 0.1;
  }

  75% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes lightMe4 {
  0% {
    opacity: 0.1;
  }

  75% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slider {
  0% {
    transform: translateY(0);
  }

  10.5% {
    transform: translateY(0);
  }

  12.5% {
    transform: translateY(-40px);
  }

  23% {
    transform: translateY(-40px);
  }

  25% {
    transform: translateY(-80px);
  }

  35% {
    transform: translateY(-80px);
  }

  37.5% {
    transform: translateY(-120px);
  }

  47.5% {
    transform: translateY(-120px);
  }

  50% {
    transform: translateY(-160px);
  }

  60% {
    transform: translateY(-160px);
  }

  62.5% {
    transform: translateY(-200px);
  }

  72.5% {
    transform: translateY(-200px);
  }

  75% {
    transform: translateY(-240px);
  }

  85% {
    transform: translateY(-240px);
  }

  87.5% {
    transform: translateY(-280px);
  }

  100% {
    transform: translateY(-280px);
  }
}

@keyframes slider {
  0% {
    transform: translateY(0);
  }

  10.5% {
    transform: translateY(0);
  }

  12.5% {
    transform: translateY(-40px);
  }

  23% {
    transform: translateY(-40px);
  }

  25% {
    transform: translateY(-80px);
  }

  35% {
    transform: translateY(-80px);
  }

  37.5% {
    transform: translateY(-120px);
  }

  47.5% {
    transform: translateY(-120px);
  }

  50% {
    transform: translateY(-160px);
  }

  60% {
    transform: translateY(-160px);
  }

  62.5% {
    transform: translateY(-200px);
  }

  72.5% {
    transform: translateY(-200px);
  }

  75% {
    transform: translateY(-240px);
  }

  85% {
    transform: translateY(-240px);
  }

  87.5% {
    transform: translateY(-280px);
  }

  100% {
    transform: translateY(-280px);
  }
}

@-webkit-keyframes blink {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.75;
  }
}

@keyframes blink {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 0.75;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 1900px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1400px;
  }
}

body {
  overflow-x: hidden;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
}

#webdevelopment.is-sticky {
  position: fixed;
  top: 118px;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  border-bottom: 1px solid whitesmoke;
  border-top: 1px solid whitesmoke;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.fa-arrow-right:before {
  position: relative;
}

.fa-arrow-left:before {
  position: relative;
}

.teamsliderabout .owl-carousel .owl-item {
  height: 500px;
  position: relative;
  transform: scale(0.7);
  -ms-transform: scale(0.7);
  transition: all 0.2s;
  -webkit-transform: all 0.2s;
  z-index: 1;
}

.teamsliderabout figcaption {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px rgb(72 69 224 / 10%);
  transition: 0.5s;
  padding: 15px 10px;
  margin-top: -10px;
  position: relative;
}

.teamsliderabout .owl-carousel .owl-item.center {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.teamsliderabout .owl-carousel img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  overflow: initial !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 440px;
  object-fit: cover;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.teamsliderabout .barcontact123 {
  height: 51px;
  width: 2px;
  background: #3fc6f1;
  margin: 0;
  position: relative;
  border-radius: 0;
  margin-top: 0px;
  float: left;
  margin-right: 10px;
}

.teamsliderabout .owl-item.big {
  transform: scale(1);
  -ms-transform: scale(1);
  z-index: 3;
  background-image: url("https://w3h3q6z4.stackpathcdn.com/wp-content/uploads/2022/07/prowebLogo.svg");
  background-size: 90% 63%;
  background-repeat: no-repeat;
}

.teamsliderabout .owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: block !important;
}

.teamsliderabout .owl-theme .owl-nav [class*="owl-"] {
  color: #2882c4;
  font-size: 20px;
  margin: 5px;
  padding: 4px 7px;
  background: #f5f1f100;
  display: inline-block;
  cursor: pointer;
  border-radius: 50px;
}

.teamsliderabout .fa-arrow-left:before {
  position: relative;
  font-size: 20px;
}

.teamsliderabout .fa-arrow-right:before {
  position: relative;
  font-size: 20px;
}

.teamsliderabout .owl-theme .owl-nav [class*="owl-"]:hover {
  background: #86979100;
  color: #3fc6f1;
  font-size: 20px;
  text-decoration: none;
}

.teamSlider .slick-prev {
  left: -35px;
}

section.spacing {
  padding-top: 80px;
  text-align: center;
  padding-bottom: 30px;
}

section.spacing h2 {
  width: 100% !important;
  margin: auto;
  color: black;
}

section.spacing h2 span {
  color: #f7993b;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid #dee2e600;
}

.faq-list {
  border-bottom: 0px solid #fff4e9;
  padding-bottom: 30px;
  background: none;
  margin-bottom: 14px;
  border-radius: 10px;
  padding: 15px;
  min-height: 80px;
  margin-top: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.faq-title h3 {
  font-size: 18px;
  line-height: 117.5%;
  cursor: pointer;
  text-transform: capitalize;
  color: #333333;
  padding-right: 30px;
}

.faq-title {
  position: relative;
  display: flex;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.faq-des {
  text-align: left !important;
}

.faq-des p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  text-align: left !important;
}

.arrow {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 7px;
  top: 5px;
  transform: rotate(45deg);
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  cursor: pointer;
}

.up {
  transform: rotate(224deg);
  top: 11px;
}

.clientSlider .slick-prev {
  left: -40px;
}

.clientSlider .slick-prev,
.slick-next {
  top: 50%;
}

.single-feedback.slick-slide.ng-star-inserted.slick-current.slick-active {
  height: 380px;
}

@media only screen and (max-width: 1250px) and (min-width: 768px) {
  .spacing h2 {
    font-size: 30px;
  }

  .clientSlider2 .slick-dots {
    bottom: 100px !important;
    right: -140px !important;
  }

  .faq-title h6 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1400px) {
  .spacing h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .spacing h2 {
    font-size: 23px;
    line-height: 1.4;
    width: 100%;
  }

  section.spacing {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .faq-title h6 {
    font-size: 14px;
  }

  .faq-list {
    min-height: unset;
    margin-bottom: 0%;
  }

  .teamSlider .slick-next::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    right: -33px;
  }

  .teamSlider .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: 10px;
    top: 29px;
  }

  .teamSlider .slick-prev::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    left: -24px;
  }

  .teamSlider .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 8px;
    top: 29px;
  }

  .partner-mobrow .slick-next::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    right: -33px;
  }

  .partner-mobrow .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: 12px;
    top: 29px;
  }

  .partner-mobrow .slick-prev::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    left: -24px;
  }

  .partner-mobrow .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 6px;
    top: 29px;
  }

  .processSlider .slick-next::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    right: -50px;
    top: 0%;
  }

  .processSlider .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: 28px;
    top: 20px;
  }

  .processSlider .slick-prev::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    left: -40px;
    top: 0%;
  }

  .processSlider .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: -5px;
    top: 20px;
  }

  .partner-inner .slick-prev {
    top: 47% !important;
  }

  .partner-inner .slick-next {
    top: 47% !important;
  }

  .seoSlider .slick-next::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    right: -15px;
  }

  .seoSlider .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: -8px;
    top: 29px;
  }

  .seoSlider .slick-prev::before {
    content: "" !important;
    width: 50px;
    position: absolute;
    height: 50px;
    background: #f7993b;
    border-radius: 50px;
    left: -15px;
  }

  .seoSlider .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 18px;
    top: 29px;
  }

  .brandSlider .slick-prev {
    left: 0px;
    z-index: 9999 !important;
  }

  .brandSlider .slick-next {
    right: 0px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 50px;
    height: 50px;
  }

  .carousel-control-prev {
    left: -50px;
  }

  .carousel-control-next {
    right: -50px;
  }
}

@media only screen and (max-width: 550px) {
  .spacing h2 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 400px) {
  .teamSlider .slick-next::before {
    content: "" !important;
    width: 45px;
    position: absolute;
    height: 45px;
    background: #f7993b;
    border-radius: 50px;
    right: -33px;
  }

  .teamSlider .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: 13px;
    top: 27px;
  }

  .teamSlider .slick-prev::before {
    content: "" !important;
    width: 45px;
    position: absolute;
    height: 45px;
    background: #f7993b;
    border-radius: 50px;
    left: -24px;
  }

  .teamSlider .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 6px;
    top: 29px;
  }

  .partner-inner.slick-prev,
  .partner-inner.slick-next {
    top: 47% !important;
  }

  .teamSlider .slick-prev {
    left: 0px;
  }

  .partner-mobrow .slick-next::before {
    content: "" !important;
    width: 45px;
    position: absolute;
    height: 45px;
    background: #f7993b;
    border-radius: 50px;
    right: -33px;
  }

  .partner-mobrow .slick-next::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    left: 13px;
    top: 28px;
  }

  .partner-mobrow .slick-prev::before {
    content: "" !important;
    width: 45px;
    position: absolute;
    height: 45px;
    background: #f7993b;
    border-radius: 50px;
    left: -24px;
  }

  .partner-mobrow .slick-prev::after {
    content: "" !important;
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 6px;
    top: 29px;
  }
}

.overflow-hiden {
  overflow: hidden !important;
  scroll-behavior: unset !important;
}
.stickBootomIcon{
  position: fixed;
  /* background: red; */
  width: -webkit-fill-available;
}
app-dedicated-team{
  @media screen and (max-width: 767px) {
    .brandSlider .slick-prev {
        left: -4px !important;
        z-index: 9999 !important;
    }
    .brandSlider .slick-next {
        right: 5px !important;
        z-index: 9999 !important;
    }
    .newClientSlider2clss .slick-dots {
      bottom: 20px !important;
  }
}
}
app-quoate-model body{
 /* position: fixed !important; */
 /* height: 100% !important;
 width: 100% !important; */
 margin: 0 !important;
 padding: 0 !important;
 box-sizing: border-box !important;
}